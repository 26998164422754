import React, { useEffect, useState } from 'react'
import Loader from './Loader';
// import NewDrawings from './NewDrawings';
import FolderItem from './FolderItem';
import axios from 'axios';
import NewFile from './NewFile';
import NewFolder from './NewFolder';

const Comment = ({ newFolder, setNewFolder, comments, pow, plans }) => {
    // component state
    const [drawings, setDrawings] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [drawingsFolders, setDrawingsFolders] = useState([]);
    const [comment, setComment] = useState(false);

    // api endpoints
    // const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrl2 = process.env.REACT_APP_BASE_URL2;
    const endPoint = "/drawings";

    useEffect(() => {
        const getDrawings = async () => {
            try {
                const res = await axios.get(`${baseUrl2}${endPoint}`);
                // console.log(res.data);
                setDrawings(res.data);
                setDataLoaded(true);
            } catch (error) {
                console.log(error);
            }
        };
        getDrawings();
    }, [baseUrl2]);

    useEffect(() => {
        if (drawings) {
            const folders = drawings.filter((drawing) => !drawing.is_file);
            setDrawingsFolders(folders);
        }
    }, [drawings]);

    return (
        <>
            {dataLoaded ? (
                <div className="flex flex-col">
                    {comment &&
                        <NewFile
                            comment={comments}
                            program={pow}
                            plans={plans}
                            setAddFile={setComment}
                        />
                    }
                    {drawingsFolders.map((drawingFolder, index) => (
                        <div key={drawingFolder.guid}>
                            <FolderItem
                                folder={drawingFolder}
                                comments={comments}
                                pow={pow}
                                plans={plans}
                                setAddFile={setComment}
                            />
                        </div>
                    ))}
                    <NewFolder
                        newFolder={newFolder}
                        setNewFolder={setNewFolder}
                        setFolders={setDrawings}
                    />
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
}

export default Comment
