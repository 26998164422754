import * as THREE from "three";
import config from "../config";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import { Modes } from "../utils";
class NavRing extends THREE.Mesh {
  /**
   * Represents a navigational ring in a 3D scene using Three.js.
   * @param {Object} currPreviewRingObj - Reference to the current preview ring object.
   * @param {Object} lastHoveredNavigationObj - Reference to the last hovered navigation object.
   * @param {Object} navigationPoints - List of navigation points.
   * @param {boolean} isCreatingPrevNav - State indicating if a preview navigation is being created.
   * @param {Function} setCurrentImageIndex - Function to set the current image index.
   * @param {number} nav_point_idx - Index of the navigation point.
   * @param {Object} sceneRef - Reference to the scene.
   * @param {Object} navigation_metadata - Metadata related to navigation.
   * @param {boolean} isPreview - Flag indicating if it's a preview mode.
   */
  constructor({
    currPreviewRingObj,
    lastHoveredNavigationObj,
    navigationRingsBuffer,
    currModeRef,
    setCurrMode,
    setCurrentImageIndex,
    nav_point_idx,
    sceneRef,
    navigation_metadata,
    isPreview = false
  }) {
    super();

    const ringGeometry = new THREE.RingGeometry(7, 9, 20);
    const innerCircle = new THREE.CircleGeometry(5, 20);
    const concentric = BufferGeometryUtils.mergeBufferGeometries([
      innerCircle,
      ringGeometry,
    ]);

    this.geometry = concentric;

    this.material = new THREE.MeshBasicMaterial({
      color: isPreview ? config.preview_nav_ring_color : config.nav_ring_color,
      transparent: true,
      opacity: navigation_metadata.opacity || 0.3,
    });

    this.idx = navigation_metadata.scene_idx || 0;
    this.rotation.x = THREE.MathUtils.degToRad(navigation_metadata.xRotation);
    this.position.copy(navigation_metadata.position);
    this.setCurrMode = setCurrMode
    this.currPreviewRingObj = currPreviewRingObj;
    this.lastHoveredNavigationObj = lastHoveredNavigationObj;
    this.navigationRingsBuffer = navigationRingsBuffer;
    this.currModeRef = currModeRef;
    this.setCurrentImageIndex = setCurrentImageIndex;
    this.nav_point_idx = nav_point_idx;
    this.sceneRef = sceneRef;
  }

  /**
   * Changes the opacity of the ring when hovered.
   * @param {Event} e - The event object.
   */
  onHover(e) {
    this.lastHoveredNavigationObj.current = this;
    this.material.opacity = 0.7;
  }

  /**
   * Resets the opacity when hover stops.
   * @param {Event} e - The event object.
   */
  onStopHover(e) {
    this.lastHoveredNavigationObj.current = null;
    this.material.opacity = 0.3;
  }

  onHoverDelete(e) {
    this.lastHoveredNavigationObj.current = this;
    this.material.opacity = 0.7;
    this.material.color.set(config.delete_nav_ring_color)
  }

  onStopHoverDelete(e) {
    this.lastHoveredNavigationObj.current = null;
    this.material.opacity = 0.3;
    this.material.color.set(config.nav_ring_color)
  }

  /**
   * Sets the current image index for navigation.
   */
  onNavigate() {
    this.setCurrentImageIndex(this.idx);
  }

  /**
   * Moves the ring to a new position.
   * @param {Object} pos - The new position.
   */
  moveTo(pos) {
    this.position.set(pos.x, pos.y + config.moveNavRingoffset, pos.z);
  }

  /**
   * Places a preview navigation point.
   */
  placePreviewNavPoint() {
    this.material.color.set(config.nav_ring_color);
    this.material.opacity = 0.3;
    const pos = this.position;
    this.position.set(pos.x, pos.y - config.moveNavRingoffset, pos.z);
    this.setCurrMode(Modes.DEFAULT);
    this.idx = this.nav_point_idx.current;
    this.currModeRef.current = Modes.DEFAULT;
    this.currPreviewRingObj.current = null;
    this.navigationRingsBuffer.current = [...this.navigationRingsBuffer.current, this];
  
  }

  /**
   * Adds the preview ring to the scene.
   */
  addPreviewToWorld() {
    this.currPreviewRingObj.current = this;
    this.sceneRef.current.add(this);
  }

    /**
     * Placeholder for removing the ring from the scene.
     */
  removeFromWorld() {
      if (this.lastHoveredNavigationObj.current === this) {
        this.lastHoveredNavigationObj.current = null;
      }

      if (this.sceneRef.current) {
        this.sceneRef.current.remove(this);
      }

      if (this.currModeRef.current == Modes.DeleteNav) {
        this.currModeRef.current = Modes.DEFAULT;
        this.setCurrMode(Modes.DEFAULT)

      }
      this.navigationRingsBuffer.current = this.navigationRingsBuffer.current.filter(navRing => navRing !== this);
      this.currPreviewRingObj.current = null;
  }

  addToWorld(){
    this.sceneRef.current.add(this)
    this.navigationRingsBuffer.current = [...this.navigationRingsBuffer.current, this]
  }

  

}

export default NavRing;
