import axios from "axios";
import React, { useState } from "react";
import { Delete } from "@mui/icons-material";
import { MdOutlineFileUpload } from "react-icons/md";

const NewProject = ({ newProject, setNewProject }) => {
  const [projectName, setProjectName] = useState("");
  const [projectDesc, setProjectDesc] = useState("");
  const [file, setFile] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL2;
  const endPoint = "/projects";
  const headers = {
    "ngrok-skip-browser-warning": 1,
  };

  const createProject = async () => {
    const data = new FormData();
    data.append("name", projectName);
    data.append("description", projectDesc);
    data.append("projectPicture", file);
    try {
      const res = await axios.post(`${baseUrl}${endPoint}`, data, { headers });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const hideNewProject = () => {
    setNewProject(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-black bg-opacity-50 absolute inset-0"></div>
      <div className="bg-white min-w-[300px] max-w-[350px] rounded-[7px] z-10">
      <div className="p-5">
      <h3 className="text-center font-light mb-4">New Project</h3>
        <div className="mb-4">
          
          <input
            id="projectName"
            type="text"
            className="focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm text-[15px] placeholder:text-[#bababa] border p-2 border-[#BABABA] rounded-[7px]"
            placeholder="Enter project name"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <textarea
            id="projectDesc"
            className="focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm text-[15px] placeholder:text-[#bababa] border p-2 border-[#BABABA] rounded-[7px]"
            placeholder="Enter project description"
            value={projectDesc}
            onChange={(e) => setProjectDesc(e.target.value)}
            required
            rows={4} // Set the number of rows for the textarea
          />
        </div>
        <div className="flex justify-between items-center">
          <span className="mr-4 text-[15px] text-[#333]">{file ? file.name : "Project Picture"}</span>
          <div className=" flex items-center gap-1 cursor-pointer bg-[#333333] text-white text-[17px] font-light py-1 px-2 rounded-[10px]">
          <label htmlFor="file" >Upload</label>
          <MdOutlineFileUpload />
          </div>
          <input
            type="file"
            id="file"
            className="hidden"
            accept="image/png, image/jpg, image/jpeg"
            onChange={(e) => setFile(e.target.files[0])}
          />
          {file && (
            <div className="cursor-pointer ml-4" onClick={() => setFile(null)}>
              <Delete sx={{ color: "red" }} />
            </div>
          )}
        </div>
      </div>
       
        <div className="flex justify-between">
          <p className="cursor-pointer bg-[#D9D9D9] text-center text-[#DB0707] w-3/6 rounded-es-[7px] font-light px-4 py-2" onClick={hideNewProject}>Cancel</p>
          <p className="cursor-pointer bg-[#333333] text-center w-3/6 rounded-ee-[7px] text-white font-light px-4 py-2" onClick={createProject}>Confirm</p>
        </div>
      </div>
    </div>
  );
};

export default NewProject;
