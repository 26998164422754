// import axios from "axios";
import { Delete } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
// import styled from "styled-components";

const UploadComment = ({ setAddFile }) => {
    // component states
    const [commentName, setCommentName] = useState("");
    const [commentDesc, setCommentDesc] = useState("");
    const [showConfirmButton, setShowConfirmButton] = useState(false);

    // api endpoints
    // const baseUrl = process.env.REACT_APP_BASE_URL2;

    // show confirm button
    useEffect(() => {
        if (commentName) {
            setShowConfirmButton(true)
        } else {
            setShowConfirmButton(false)
        }
    }, [commentName])

    // hide new drawings container
    // const hideNewReport = () => {
    //     setNewDrawing(false);
    // };

    // choose folder upload
    // const selectFolder = () => {
    //     setShowChooseOption(false);
    //     setShowFolderUpload(true);
    //     setShowConfirmButton(true);
    // };

    // choose file upload
    // const selectFile = () => {
    //     setShowChooseOption(false);
    //     setShowFileUpload(true);
    //     setShowConfirmButton(true);
    // };

    // // get drawings
    // const getDrawings = async () => {
    //     try {
    //         const res = await axios.get(`${baseUrl}${endPoint}`);
    //         console.log(res.data);
    //         // setDrawings(res.data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // // create new drawing file or folder
    // const createDrawing = async () => {
    //     const data = new FormData();
    //     if (showFileUpload) {
    //         data.append("document", file);
    //         data.append("folderId", folderId);
    //         try {
    //             const res = await axios.post(`${baseUrl}${endPoint}`, data, {
    //                 headers: {
    //                     "ngrok-skip-browser-warning": 1,
    //                 },
    //             });
    //             console.log(res.data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }

    //     if (showFolderUpload) {
    //         const folder = {
    //             name: folderName,
    //             folderId: folderId,
    //         };
    //         try {
    //             const res = await axios.post(
    //                 `${baseUrl}${endPoint}/create-folder`,
    //                 folder
    //             );
    //             console.log(res.data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }

    //     getDrawings();
    //     setNewDrawing(false);
    // };


    return (
        <div className="fixed top-0 left-0 bg-[#00000048] z-20 flex items-center justify-center w-full h-full">
            <div
                className="w-[300px] bg-white rounded-3xl z-30"
                style={{ fontFamily: "Lexend, sans-serif" }}
            >
                <h3 className="my-5 text-base font-light text-center text-black">New Comment</h3>
                <div className="flex flex-col gap-2 px-5">
                    <input
                        type="text"
                        className="py-2 px-1 border-[0.5px] border-[#8b8b8b] rounded-[7px] focus:outline-none"
                        placeholder="Name"
                        value={commentName}
                        onChange={(e) => setCommentName(e.target.value)}
                    />
                    <input
                        type="text"
                        className="py-2 px-1 border-[0.5px] border-[#8b8b8b] rounded-[7px] focus:outline-none"
                        placeholder="Description"
                        value={commentDesc}
                        onChange={(e) => setCommentDesc(e.target.value)}
                    />
                </div>
                <div className="flex w-full mt-5 text-base font-light" style={{ fontFamily: "Lexent, sans-serif" }}>
                    <div
                        className={`flex-1 text-center text-red-500 m-0 bg-[#d9d9d9] p-2.5 rounded-bl-3xl ${!commentName && "rounded-br-3xl"}`}
                        onClick={() => setAddFile(false)}
                    >
                        <span style={{ cursor: "pointer" }}>
                            Cancel
                        </span>
                    </div>
                    {showConfirmButton && (
                        <div className="flex-1 text-center text-white m-0 bg-[#333333] p-2.5 rounded-br-3xl">
                            <span style={{ cursor: "pointer" }}>
                                Confirm
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const UploadProgram = ({ setAddFile }) => {
    // component states
    const [programName, setProgramName] = useState("");
    const [programDesc, setProgramDesc] = useState("");
    const [showConfirmButton, setShowConfirmButton] = useState(false);

    // api endpoints
    // const baseUrl = process.env.REACT_APP_BASE_URL2;

    // show confirm button
    useEffect(() => {
        if (programName) {
            setShowConfirmButton(true)
        } else {
            setShowConfirmButton(false)
        }
    }, [programName])

    // hide new drawings container
    // const hideNewReport = () => {
    //     setNewDrawing(false);
    // };

    // choose folder upload
    // const selectFolder = () => {
    //     setShowChooseOption(false);
    //     setShowFolderUpload(true);
    //     setShowConfirmButton(true);
    // };

    // choose file upload
    // const selectFile = () => {
    //     setShowChooseOption(false);
    //     setShowFileUpload(true);
    //     setShowConfirmButton(true);
    // };

    // // get drawings
    // const getDrawings = async () => {
    //     try {
    //         const res = await axios.get(`${baseUrl}${endPoint}`);
    //         console.log(res.data);
    //         // setDrawings(res.data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // // create new drawing file or folder
    // const createDrawing = async () => {
    //     const data = new FormData();
    //     if (showFileUpload) {
    //         data.append("document", file);
    //         data.append("folderId", folderId);
    //         try {
    //             const res = await axios.post(`${baseUrl}${endPoint}`, data, {
    //                 headers: {
    //                     "ngrok-skip-browser-warning": 1,
    //                 },
    //             });
    //             console.log(res.data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }

    //     if (showFolderUpload) {
    //         const folder = {
    //             name: folderName,
    //             folderId: folderId,
    //         };
    //         try {
    //             const res = await axios.post(
    //                 `${baseUrl}${endPoint}/create-folder`,
    //                 folder
    //             );
    //             console.log(res.data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }

    //     getDrawings();
    //     setNewDrawing(false);
    // };


    return (
        <div className="fixed top-0 left-0 bg-[#00000048] z-20 flex items-center justify-center w-full h-full">
            <div
                className="w-[300px] bg-white rounded-3xl z-30"
                style={{ fontFamily: "Lexend, sans-serif" }}
            >
                <h3 className="my-5 text-base font-light text-center text-black">New POW</h3>
                <div className="flex flex-col gap-2 px-5">
                    <input
                        type="text"
                        className="py-2 px-1 border-[0.5px] border-[#8b8b8b] rounded-[7px] focus:outline-none"
                        placeholder="Name"
                        value={programName}
                        onChange={(e) => setProgramName(e.target.value)}
                    />
                    <input
                        type="text"
                        className="py-2 px-1 border-[0.5px] border-[#8b8b8b] rounded-[7px] focus:outline-none"
                        placeholder="Description"
                        value={programDesc}
                        onChange={(e) => setProgramDesc(e.target.value)}
                    />
                </div>
                <div className="flex w-full mt-5 text-base font-light" style={{ fontFamily: "Lexent, sans-serif" }}>
                    <div
                        className={`flex-1 text-center text-red-500 m-0 bg-[#d9d9d9] p-2.5 rounded-bl-3xl ${!programName && "rounded-br-3xl"}`}
                        onClick={() => setAddFile(false)}
                    >
                        <span style={{ cursor: "pointer" }}>
                            Cancel
                        </span>
                    </div>
                    {showConfirmButton && (
                        <div className="flex-1 text-center text-white m-0 bg-[#333333] p-2.5 rounded-br-3xl">
                            <span style={{ cursor: "pointer" }}>
                                Confirm
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const UploadPlans = ({ setAddFile }) => {
    // component states
    const [file, setFile] = useState(null);
    const [showConfirmButton, setShowConfirmButton] = useState(false);

    // api endpoints
    // const baseUrl = process.env.REACT_APP_BASE_URL2;

    // show confirm button
    useEffect(() => {
        if (file) {
            setShowConfirmButton(true)
        } else {
            setShowConfirmButton(false)
        }
    }, [file])

    // hide new drawings container
    // const hideNewReport = () => {
    //     setNewDrawing(false);
    // };

    // choose folder upload
    // const selectFolder = () => {
    //     setShowChooseOption(false);
    //     setShowFolderUpload(true);
    //     setShowConfirmButton(true);
    // };

    // choose file upload
    // const selectFile = () => {
    //     setShowChooseOption(false);
    //     setShowFileUpload(true);
    //     setShowConfirmButton(true);
    // };

    // // get drawings
    // const getDrawings = async () => {
    //     try {
    //         const res = await axios.get(`${baseUrl}${endPoint}`);
    //         console.log(res.data);
    //         // setDrawings(res.data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // // create new drawing file or folder
    // const createDrawing = async () => {
    //     const data = new FormData();
    //     if (showFileUpload) {
    //         data.append("document", file);
    //         data.append("folderId", folderId);
    //         try {
    //             const res = await axios.post(`${baseUrl}${endPoint}`, data, {
    //                 headers: {
    //                     "ngrok-skip-browser-warning": 1,
    //                 },
    //             });
    //             console.log(res.data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }

    //     if (showFolderUpload) {
    //         const folder = {
    //             name: folderName,
    //             folderId: folderId,
    //         };
    //         try {
    //             const res = await axios.post(
    //                 `${baseUrl}${endPoint}/create-folder`,
    //                 folder
    //             );
    //             console.log(res.data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }

    //     getDrawings();
    //     setNewDrawing(false);
    // };


    return (
        <div className="fixed top-0 left-0 bg-[#00000048] z-20 flex items-center justify-center w-full h-full">
            <div
                className="w-[300px] bg-white rounded-3xl z-30"
                style={{ fontFamily: "Lexend, sans-serif" }}
            >
                <h3 className="my-5 text-base font-light text-center text-black">New Plan</h3>
                <div className="flex items-center justify-center px-5">
                    <div className="flex items-center justify-between w-full font-extralight">
                        <span style={{ marginRight: "20px" }}>
                            {file ? file.name : "Select File"}
                        </span>
                        <div className="flex items-center">
                            {!file && <label className="p-1 rounded-md bg-secondary text-primary" htmlFor="file">
                                Upload
                                <input
                                    type="file"
                                    id="file"
                                    style={{ display: "none" }}
                                    accept="application/pdf"
                                    onChange={(e) => {
                                        setFile(e.target.files[0]);
                                    }}
                                />
                            </label>}
                            {file && <div className="flex items-center justify-center"
                                onClick={() => {
                                    setFile(null);
                                }}
                            >
                                <Delete sx={{ color: "red" }} />
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="flex w-full mt-5 text-base font-light" style={{ fontFamily: "Lexent, sans-serif" }}>
                    <div
                        className={`flex-1 text-center text-red-500 m-0 bg-[#d9d9d9] p-2.5 rounded-bl-3xl ${!file && "rounded-br-3xl"}`}
                        onClick={() => setAddFile(false)}
                    >
                        <span style={{ cursor: "pointer" }}>
                            Cancel
                        </span>
                    </div>
                    {showConfirmButton && (
                        <div className="flex-1 text-center text-white m-0 bg-[#333333] p-2.5 rounded-br-3xl">
                            <span style={{ cursor: "pointer" }}>
                                Confirm
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const NewFile = ({ plans, comment, program, setAddFile }) => {
    return (
        <div className="relative">
            {comment && (
                <UploadComment setAddFile={setAddFile} />
            )}
            {program && (
                <UploadProgram setAddFile={setAddFile} />
            )}
            {plans && (
                <UploadPlans setAddFile={setAddFile} />
            )}
        </div>
    );
};

export default NewFile;
