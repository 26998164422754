import { css } from "styled-components"

export const mobile = (props) => {
    return css`
    @media only screen and (max-width: 768px) {
        ${props}
    }
    `
}

export const mobile2 = (props) => {
    return css`
    @media only screen and (max-height: 620px) {
        ${props}
    }
    `
}

export const mobile3 = (props) => {
    return css`
    @media only screen and (max-height: 580px) {
        ${props}
    }
    `
}