import { BorderColorRounded, Delete } from "@mui/icons-material";
import React, { useState } from "react";
import styled from "styled-components";
import profilePicture from "../media/profile photo.png";
// import Dropdown from "../components/Dropdown";
// import { mobile } from "../responsive";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import logo from "../media/Buildr LOGO.png"

// const Container = styled.div`
//   height: 100%;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   ${mobile({
//   display: "block",
// })}
// `;

// const ProfileContainer = styled.div`
//   padding: 20px;
//   width: 60vw;

//   ${mobile({
//   width: "100%",
// })}
// `;

// const Heading = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// `;

// const HeadingIcon = styled.div`
//   display: flex;
//   align-items: center;
//   margin-right: 10px;
//   font-weight: 200;
//   cursor: pointer;
// `;

// const HeadingText = styled.div`
//   display: flex;
//   align-items: center;
//   font-weight: 500;
//   font-family: "Lexend", sans-serif;
// `;

// const ProfilePictureContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin: 30px 0;
// `;

// const ExtraContainer = styled.div`
//   width: 136px;
//   position: relative;
// `;

// const Picture = styled.img`
//   height: 136px;
//   width: 136px;
//   object-fit: cover;
//   object-position: center;
//   border-radius: 50%;
// `;

// const EditIcon = styled.div`
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   height: 32px;
//   width: 32px;
//   background-color: #1e73bd;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 5px;

//   &:hover {
//     cursor: pointer;
//   }
// `;

// const FormContainer = styled.div``;

// const Form = styled.form`
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
// `;

const Input = styled.input`
  height: 50px;
  border: 0.5px solid #515151;
  padding: 10px;
  color: black;
  border-radius: 7px;
  font-size: 15px;
  font-weight: 400;
  font-family: "Lexend", sans-serif;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-weight: 400;
    font-family: "Lexend", sans-serif;
  }
`;

const CompanyLogoContainer = styled.div`
  border: 0.5px solid #515151;
  padding: 10px;
  color: #9DA4B0;
  border-radius: 7px;
  font-size: 15px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-family: "Lexend", sans-serif;
  background-color: white;
`;

// const CompanyLogo = styled.input`
//   display: none;
//   color: #757575;

//   &:focus {
//     outline: none;
//   }
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   align-items: center;
//   cursor: pointer;
// `;

// const Button = styled.label`
//   background-color: #1e73bd;
//   padding: 5px;
//   margin-left: 8px;
//   color: white;
//   cursor: pointer;
//   border-radius: 5px;
// `;

// const DeleteFile = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const SubmitContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   margin-top: 20px;
// `;

// const SubmitButton = styled.button`
//   padding: 10px 20px;
//   font-weight: 400;
//   font-family: "Lexend", sans-serif;
//   border: none;
//   color: white;
//   background-color: #1e73bd;
//   border-radius: 10px;
//   cursor: pointer;
// `;

const Profile = () => {
  // component states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [file, setFile] = useState(null);
  const [isFetching, setIsFetching] = useState(false)
  const navigate = useNavigate();

  // backend endpoints
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrl2 = process.env.REACT_APP_BASE_URL2;
  const endPoint = "/register";
  const headers = {
    "ngrok-skip-browser-warning": 1,
  };

  // function to handle user register
  const handleRegister = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("first_name", firstName);
    formdata.append("last_name", lastName);
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("company", companyName);
    formdata.append("country", country);
    formdata.append("phone", phoneNum);
    formdata.append("logo", file);
    try {
      setIsFetching(true)
      const res = await axios.post(`${baseUrl2}${endPoint}`, formdata, {
        headers,
      });
      console.log(res.data);
      setIsFetching(false)
      navigate("/signin");
    } catch (error) {
      setIsFetching(false)
      console.log(error);
    }
  };

  return (
    <div className="items-center justify-center block w-full h-full bg-white md:flex">
      <div className="w-full md:w-[60vw]">
        <div className="sticky top-0 left-0 z-30 bg-white">
          <div className="flex items-center justify-center pt-10 pb-4">
            <img src={logo} alt="" />
          </div>
          <div className="border-y-[0.5px] border-[#d2d2d2] font-sans font-light text-xl py-3 text-center">
            Personal Information
          </div>
        </div>
        <div className="p-5 bg-[#f0f0f0]">
          <div className="flex items-center justify-center mt-1.5 mb-2.5">
            <div className="w-[136px] relative">
              <img className="w-[136px] h-[136px] object-cover object-center rounded-full" src={profilePicture} alt="" />
              <div className="absolute bottom-0 right-0 flex items-center justify-center w-8 h-8 bg-white border border-black rounded-md">
                <BorderColorRounded sx={{ color: "#000000" }} />
              </div>
            </div>
          </div>
          <form className="flex flex-col gap-5" onSubmit={handleRegister}>
            <Input
              type="text"
              name="first_name"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              required
            />
            <Input
              type="text"
              name="last_name"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              required
            />
            <Input
              type="email"
              name="email"
              placeholder="Work Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
            />
            <Input
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
            />
            <Input
              type="text"
              name="Company Name"
              placeholder="Company Name"
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
            />
            <Input
              type="text"
              name="country"
              placeholder="Country"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />
            <Input
              type="tel"
              name="tel"
              placeholder="Phone Number"
              value={phoneNum}
              onChange={(e) => {
                setPhoneNum(e.target.value);
              }}
            />
            <CompanyLogoContainer>
              <span>{file ? file.name : "Insert Company Logo"}</span>
              <div className="flex items-center cursor-pointer">
                <label className="bg-secondary p-1.5 ml-2 text-white rounded-md" htmlFor="file">
                  Upload
                  <input
                    className="hidden focus:outline-none"
                    id="file"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                  />
                </label>
                {file && (
                  <div
                    className="flex items-center justify-center"
                    onClick={() => {
                      setFile(null);
                    }}
                  >
                    <Delete sx={{ color: "red" }} />
                  </div>
                )}
              </div>
            </CompanyLogoContainer>
            <button
              className="h-12 text-white font-normal text-[15px] bg-secondary border-none font-sans rounded-xl"
              type="submit"
            >
              {isFetching ?
                (<span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress sx={{ fontSize: "16px", color: "white" }} />
                </span>) : "CONTINUE"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
