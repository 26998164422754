import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import styled from "styled-components";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

const PageContainer = styled.div`
  position: absolute;
  top: 85%;
`;

const PDFFile = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [rect, setRect] = useState(null);

  useEffect(() => {
    const pdfContainer = document.querySelector(`#pdf`);
    if (pdfContainer) {
      const rectangle = pdfContainer.getBoundingClientRect();
      setRect(rectangle);
    }
  }, []);

  return (
    <div
      id="pdf"
      style={{ position: "relative", height: "100%", width: "100%" }}
    >
      <Document
        file={pdfUrl}
        onLoadSuccess={({ numPages }) => {
          setNumPages(numPages);
        }}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={rect?.width}
            height={rect?.height}
            loading="loading..."
          />
        ))}
      </Document>
    </div>
  );
};

export default PDFFile;
