import * as THREE from 'three';
import config from '../config'

class SphericalWorld extends THREE.Mesh { 
    constructor(scenes, currentImageIndex) {
      super()
      this.geometry =new THREE.SphereGeometry(config.world_radius, 60, 40);
      this.geometry.scale(-1, 1, 1);
      this.texture = new THREE.TextureLoader().load(
        scenes[currentImageIndex].imageUrl);
      this.texture.colorSpace = THREE.SRGBColorSpace;
      this.material = new THREE.MeshBasicMaterial({ map: this.texture })
    }
  
}

export default SphericalWorld