import { Add } from "@mui/icons-material";
// import axios from "axios";
import React, { useEffect, useState } from "react";
// import styled from "styled-components";


const UploadFolder = ({ newFolder, setNewFolder, setFolders }) => {
    // component states
    const [folderName, setFolderName] = useState("");
    const [showConfirmButton, setShowConfirmButton] = useState(false);

    // confirm button toggle
    useEffect(() => {
        if (folderName) {
            setShowConfirmButton(true)
        } else setShowConfirmButton(false)
    }, [folderName])

    // api endpoints
    // const baseUrl = process.env.REACT_APP_BASE_URL2;
    // const endPoint = "/drawings";

    // hide new drawings container
    // const hideNewReport = () => {
    //     setNewDrawing(false);
    // };

    // choose folder upload
    // const selectFolder = () => {
    //     setShowChooseOption(false);
    //     setShowFolderUpload(true);
    //     setShowConfirmButton(true);
    // };

    // choose file upload
    // const selectFile = () => {
    //     setShowChooseOption(false);
    //     setShowFileUpload(true);
    //     setShowConfirmButton(true);
    // };

    // get drawings
    // const getDrawings = async () => {
    //     try {
    //         const res = await axios.get(`${baseUrl}${endPoint}`);
    //         console.log(res.data);
    //         setDrawings(res.data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // create new drawing file or folder
    // const createDrawing = async () => {
    //     const data = new FormData();
    //     if (showFileUpload) {
    //         data.append("document", file);
    //         data.append("folderId", folderId);
    //         try {
    //             const res = await axios.post(`${baseUrl}${endPoint}`, data, {
    //                 headers: {
    //                     "ngrok-skip-browser-warning": 1,
    //                 },
    //             });
    //             console.log(res.data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }

    //     if (showFolderUpload) {
    //         const folder = {
    //             name: folderName,
    //             folderId: folderId,
    //         };
    //         try {
    //             const res = await axios.post(
    //                 `${baseUrl}${endPoint}/create-folder`,
    //                 folder
    //             );
    //             console.log(res.data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }

    //     getDrawings();
    //     setNewDrawing(false);
    // };

    return (
        <div className="absolute z-40 flex justify-center w-screen item-center">
            <div
                className="w-[300px] bg-white rounded-3xl z-30"
                style={{ fontFamily: "Lexend, sans-serif" }}
            >
                <h3 className="my-5 text-base font-semibold text-center text-black">New Folder</h3>
                <div className="flex flex-col gap-2 px-5">
                    <input
                        type="text"
                        className="py-2 px-1 border-[0.5px] border-[#8b8b8b] rounded-[7px] focus:outline-none"
                        placeholder="Name"
                        value={folderName}
                        onChange={(e) => setFolderName(e.target.value)}
                    />
                </div>
                <div className="flex w-full mt-5 text-base font-light" style={{ fontFamily: "Lexent, sans-serif" }}>
                    <div
                        className={`flex-1 text-center text-red-500 m-0 bg-[#d9d9d9] p-2.5 rounded-bl-3xl ${!folderName && "rounded-br-3xl"}`}
                        onClick={() => setNewFolder(false)}
                    >
                        <span style={{ cursor: "pointer" }}>
                            Cancel
                        </span>
                    </div>
                    {showConfirmButton && (
                        <div className="flex-1 text-center text-white m-0 bg-[#333333] p-2.5 rounded-br-3xl">
                            <span style={{ cursor: "pointer" }}>
                                Confirm
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const NewFolder = ({ newFolder, setNewFolder, setFolders }) => {
    const showNewFolder = () => {
        setNewFolder(true);
    };

    return (
        <>
            {newFolder && (
                <UploadFolder
                    newFolder={newFolder}
                    setNewFolder={setNewFolder}
                    setFolders={setFolders}
                />
            )}
            <div style={{ flex: "1", maxWidth: "100%" }} className="mb-20">
                <div
                    className="w-full min-h-[70px] p-5 flex items-center border-dashed border-b-[0.5px] border-[#9c9c9c] relative"
                    onClick={showNewFolder}
                >
                    <div className="flex items-center font-light text-base text-[#8b8b8b]" style={{ fontFamily: "Lexend, sans-serif" }}>
                        <div className="h-8 w-8 border-dashed border-[0.5px] border-[#9c9c9c] flex items-center justify-center mr-5">
                            <Add />
                        </div>
                        <p className="m-0 text-[15px]">
                            <span>Add new folder</span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewFolder;
