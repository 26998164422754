import React, { useContext, useState } from "react";
import styled from "styled-components";
import LogoImg from "../media/Buildr LOGO.png";
import backgroundImg from "../media/bgImg.jpg";
import mail from "../media/email.png";
import google from "../media/google.png";
import autodesk from "../media/autodesk.png";
import phone from "../media/phone.png"
import whatsapp from "../media/whatsapp.png"
import { mobile, mobile3 } from "../responsive";
// import axios from "axios";
import { ArrowForward } from "@mui/icons-material";
import { loginCall } from "../loginCall";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { LuMail } from "react-icons/lu";
import { LiaPhoneSolid } from "react-icons/lia";
import { BsWhatsapp } from "react-icons/bs";
import { Logo } from "../media/svgs/logo";


const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImg});
  background-size: cover;
  opacity: 0.6;

  ${mobile({
  display: "none",
})}

  ${mobile3({
  display: "none",
})}
`;

const RememberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const RememberText = styled.label`
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  color: #9a9a9a;
`;

const Input2 = styled.input`
  /* Hide the default checkbox */
  position: absolute;
  opacity: 0;

  /* Create a custom checkbox appearance */
  & + label::after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 2px solid black;
    background-color: #fff;
    margin: 0px 5px;
  }

  /* Customize the checkbox icon when checked */
  &:checked + label::after {
    content: "\u2713";
    color: white;
    font-size: 16px;
    background-color: #000000;
    border-color: #000000;
    text-align: center;
  }
`;


const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { isFetching, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const baseUrl2 = process.env.REACT_APP_BASE_URL2;
  const endPoint2 = "/google/auth";
  const endPoint3 = "/autodesk/auth";

  // sign in user
  const handleSignIn = async () => {
    const data = {
      email: email,
      password: password,
    };
    loginCall(data, dispatch);
  };

  // create new account
  const handleCreateAccount = () => {
    navigate("/signup");
  };

  return (
    <div className="relative w-full min-h-screen md:pt-10 md:pb-5 bg-white md:flex md:items-center md:justify-center">
      <Background />
      <div className="p-5 md:w-[60vw] max-w-6xl mx-0 w-full min-h-screen bg-white z-30 rounded-[20px] md:h-auto">
        {isFetching && (
          <div className="absolute top-0 left-0 w-full h-full bg-[#dfdfdf59] z-50 flex items-center justify-center">
            <Loader />
          </div>
        )}
        <div className="flex flex-col items-center justify-center m-2.5">
          {/* <img src={LogoImg} alt="" /> */}
          {Logo}
          <p className="text-[15px] jeju text-[#686767] text-center">Your site, Anytime, Anywhere.</p>
        </div>
        <div className="mt-7">
          <div className="flex flex-col">
            <div className="flex flex-col gap-5 md:gap-7 mb-5">
              <input
                className="h-10 md:h-12 p-2.5 border-[1px] rounded-md font-sans font-normal focus:outline-none text-sm border-[#515151] text-[#939393]"
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <input
                className="h-10 md:h-12 p-2.5 border-[1px] rounded-md font-sans font-normal focus:outline-none text-sm border-[#515151] text-[#939393]"
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <div className="flex justify-end">
              <button
                className="outline-none m-0 font-extralight text-[14px] text-right text-[#6a6a6a] border-none hover:text-primary"
              >
                Forgot password?
              </button>
            </div>
            <button
              className="mx-0 my-3.5 h-10 md:h-12 bg-primary text-black border border-solid border-black rounded-xl font-normal hover:border-[1px] hover:text-primary hover:bg-black hover:border-primary"
              onClick={handleSignIn}
            >
              SIGN IN
            </button>
            <RememberContainer>
              <Input2 type="checkbox" id="rememberMe" />
              <RememberText htmlFor="rememberMe">Remember me</RememberText>
            </RememberContainer>
          </div>
        </div>
        <div className="flex items-center border-b-2 pb-2 border-[#A6A4A4] text-sm text-[#535353] hover:text-opacity-85 my-2.5 font-bold md:text-[16px]">
          {/* <span>Don't have an account?</span> */}
          <div
            className="flex items-center cursor-pointer ml-1.5 md:ml-2.5 hover:text-primary"
            onClick={handleCreateAccount}>
            <span className="font-inter">Create new account</span>
          </div>
        </div>
        {/* <hr /> */}
        <p className="mt-6 mb-2.5">Or Sign In With:</p>
        <div className="flex flex-col items-center justify-center gap-5 font-sans text-white">
          <button className="outline-none h-10 md:h-12 w-full gap-2 md:gap-3 flex items-center justify-center bg-[#333333] border-none rounded-[10px] p-1.5 no-underline">
          <LuMail fontSize="30px"/>
            <span className="text-[16px]">Mail</span>
          </button>
          <a
            className="w-full h-10 md:h-12 flex items-center justify-center bg-[#333333] border-none rounded-[10px] p-1.5 no-underline"
            href={`${baseUrl2}${endPoint2}`}
          >
            <img className="h-10 w-10 object-cover mr-2.5" src={google} alt="" />
            <span className="text-[16px]">Google</span>
          </a>
          <a
            className="w-full h-10 md:h-12 flex items-center justify-center bg-[#333333] border-none rounded-[10px] p-1.5 no-underline"
            href={`${baseUrl2}${endPoint3}`}
          >
            <img className="h-10 w-10 object-cover mr-2.5" src={autodesk} alt="" />
            <span className="text-[16px]">Autodesk</span>
          </a>
        </div>
        <div className="flex items-center justify-end gap-2.5 mt-10">
          <div className="h-14 w-14 text-white text-[30px] flex items-center justify-center bg-[#333333] rounded-full shadow-icons transform transition-transform duration-300 hover:scale-105"><LiaPhoneSolid /></div>
          <div className="h-14 w-14 text-white text-[30px] flex items-center justify-center bg-[#333333] rounded-full shadow-icons transform transition-transform duration-300 hover:scale-105"><LuMail /></div>
          <div className="h-14 w-14 text-white text-[30px] flex items-center justify-center bg-[#333333] rounded-full shadow-icons transform transition-transform duration-300 hover:scale-105"><BsWhatsapp /></div>
        </div>
        
        <footer className="pt-10 flex items-center justify-center">
        <p className="font-bold hebrew text-[#B7B6B6]">@2024BUILDR</p>
        </footer>
      </div>
    </div>
  );
};

export default SignUp;
