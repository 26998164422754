const dummy_data = {
  scenes: {
    0: {
      // "image_url" :   "https://res.cloudinary.com/hitmeeup/image/upload/v1714778619/frame_0_d8get1.jpg",
      image_url:
        "https://res.cloudinary.com/hitmeeup/image/upload/v1716755923/1_mordi_house.jpg",
      // "image_url" :   "https://threejs.org/examples/textures/2294472375_24a3b8ef46_o.jpg",
      annotations: [
        {
          position: {
            x: -192.32707246235802,
            y: 67.31096398586196,
            z: -50.78908667571105,
          },
          description: "This is annotation 1 made on scene 1",
        },
      ],
      navigation: [
        {
          position: { x: -106, y: -50, z: -15 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 1,
        },
        {
          position: { x: -145, y: -50, z: -7 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 2,
        },
        {
          position: { x: -235, y: -50, z: -2 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 3,
        },
      ],
    },

    1: {
      image_url:
        // "https://res.cloudinary.com/hitmeeup/image/upload/v1714778621/frame_72_gkptu6.jpg"
        "https://res.cloudinary.com/hitmeeup/image/upload/v1716755969/2_mordi_house.jpg",
      annotations: [
        {
          position: {
            x: -192.32707246235802,
            y: 67.31096398586196,
            z: -50.78908667571105,
          },
          description: "This is annotation 1 made on scene 2",
        },
      ],
      navigation: [
        {
          position: { x: -73, y: -50, z: -4 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 2,
        },
        {
          position: { x: 59, y: -50, z: -6 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 0,
        },
        {
          position: { x: -126, y: -50, z: -0.5 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 3,
        },
        {
          position: { x: 199.8, y: -50, z: 6.29 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 4,
        },
        {
          position: { x: -292.8, y: -50, z: 49.64 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 5,
        },
      ],
    },

    2: {
      image_url:
        // "https://res.cloudinary.com/hitmeeup/image/upload/v1714778620/frame_144_rkjbqb.jpg"
        "https://res.cloudinary.com/hitmeeup/image/upload/v1716756051/3_mordi_house.jpg",
      annotations: [],
      navigation: [
        {
          position: { x: 136, y: -50, z: -7.6 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 0,
        },
        {
          position: { x: 70, y: -50, z: -1.4 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 1,
        },
        {
          position: { x: -60.6, y: -50, z: -7.4 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 3,
        },
        {
          position: { x: -126.5, y: -50, z: -7.2 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 4,
        },
        {
          position: { x: -231.7, y: -50, z: 30.6 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 5,
        },
      ],
    },

    3: {
      image_url:
        // "https://res.cloudinary.com/hitmeeup/image/upload/v1714778620/frame_240_teawol.jpg"
        "https://res.cloudinary.com/hitmeeup/image/upload/v1716756130/4_mordi_house.jpg",
      annotations: [
        {
          position: {
            x: -192.32707246235802,
            y: 67.31096398586196,
            z: -50.78908667571105,
          },
          description: "This is annotation 1 made on scene 1",
        },
      ],
      navigation: [
        {
          position: { x: 200, y: -50, z: -30 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 0,
        },
        {
          position: { x: 121, y: -50, z: -4.4 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 1,
        },
        {
          position: { x: 68.5, y: -50, z: -0.43 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 2,
        },
        {
          position: { x: -77.4, y: -50, z: 0.87 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 4,
        },
        {
          position: { x: -149.3, y: -50, z: 9.12 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 5,
        },
        {
          position: { x: -178, y: -50, z: 93.1 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 6,
        },
      ],
    },
    4: {
      image_url:
        // "https://res.cloudinary.com/hitmeeup/image/upload/v1714778620/frame_240_teawol.jpg"
        "https://res.cloudinary.com/hitmeeup/image/upload/v1716756188/5_mordi_house.jpg",
      annotations: [
        {
          position: {
            x: -192.32707246235802,
            y: 67.31096398586196,
            z: -50.78908667571105,
          },
          description: "This is annotation 1 made on scene 1",
        },
      ],
      navigation: [
        {
          position: { x: 200, y: -50, z: -30 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 3,
        },
        {
          position: { x: -67.3, y: -50, z: 10.9 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 5,
        },
        {
          position: { x: -68, y: -50, z: 67.6 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 6,
        },
        {
          position: { x: -80.8, y: -50, z: 121.5 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 6,
        },
      ],
    },
    5: {
      image_url:
        // "https://res.cloudinary.com/hitmeeup/image/upload/v1714778620/frame_240_teawol.jpg"
        "https://res.cloudinary.com/hitmeeup/image/upload/v1716756288/6_mordi_house.jpg",
      annotations: [
        {
          position: {
            x: -192.32707246235802,
            y: 67.31096398586196,
            z: -50.78908667571105,
          },
          description: "This is annotation 1 made on scene 1",
        },
      ],
      navigation: [
        {
          position: { x: 198.8, y: -50, z: 9.1 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 3,
        },
        {
          position: { x: 111.3, y: -50, z: 11.4 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 4,
        },
        {
          position: { x: -31.1, y: -50, z: 72.8 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 6,
        },
        {
          position: { x: -39.7, y: -50, z: 172.9 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 7,
        },
      ],
    },
    6: {
      image_url:
        // "https://res.cloudinary.com/hitmeeup/image/upload/v1714778620/frame_240_teawol.jpg"
        "https://res.cloudinary.com/hitmeeup/image/upload/v1716756359/7_mordi_house.jpg",
      annotations: [
        {
          position: {
            x: -192.32707246235802,
            y: 67.31096398586196,
            z: -50.78908667571105,
          },
          description: "This is annotation 1 made on scene 1",
        },
      ],
      navigation: [
        {
          position: { x: 131, y: -50, z: 229.9 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 3,
        },
        {
          position: { x: 112.1, y: -50, z: 103.7 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 4,
        },
        {
          position: { x: 103.5, y: -50, z: 13.8 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 5,
        },
        {
          position: { x: -56.8, y: -50, z: -4.3 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 7,
        },
      ],
    },
    7: {
      image_url:
        // "https://res.cloudinary.com/hitmeeup/image/upload/v1714778620/frame_240_teawol.jpg"
        "https://res.cloudinary.com/hitmeeup/image/upload/v1716756445/8_mordi_house.jpg",
      annotations: [
        {
          position: {
            x: -192.32707246235802,
            y: 67.31096398586196,
            z: -50.78908667571105,
          },
          description: "This is annotation 1 made on scene 1",
        },
      ],
      navigation: [
        {
          position: { x: 199, y: -50, z: 163 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 3,
        },
        {
          position: { x: 191.5, y: -50, z: 97.97 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 4,
        },
        {
          position: { x: 136.7, y: -50, z: 19.08 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 5,
        },
        {
          position: { x: 59.6, y: -50, z: 8.3 },
          xRotation: 269,
          opacity: 0.3,
          scene_idx: 6,
        },
      ],
    },
  },
};

export default dummy_data;
