import React from "react";
import styled from "styled-components";
import defaultPic from "../media/homeImage.png";

// const Container = styled.div`
//   width: 100%;
//   padding: 20px 0;
//   border-bottom: 0.5px solid #e9e7e7;
// `;

// const EstateContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// `;

// const EstateIcon = styled.img`
//   height: 30px;
//   width: 30px;
//   object-fit: cover;
//   border-radius: 50%;
//   margin-right: 10px;
// `;

// const Details = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 5px;
// `;

// const Name = styled.p`
//   color: #000000;
//   font-weight: 300;
//   font-family: "Lexend", sans-serif;
//   font-size: 18px;
//   margin: 0;
// `;

// const Description = styled.p`
//   color: #a6a4a4;
//   font-weight: 200;
//   font-size: 16px;
//   font-family: "Lexend", sans-serif;
//   margin: 0;
// `;

const Estate = ({ img, name, desc }) => {
  //capitalize project name
  const capitalizeWord = (input) => {
    return input
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="w-full min-h-[71px] px-[5%] py-[10px] border-y-[0.5px] border-[#e1e1e1] bg-white">
      <div className="flex items-center justify-start">
        <img className="h-[50px] w-[50px] object-cover mr-5 rounded-[4px]" src={img ? img : defaultPic} alt="project-img" />
        <div className="flex flex-col">
          <p className="text-lg font-light text-black">{capitalizeWord(name)}</p>
          <p className="text-[#a6a4a4] font-light text-sm">{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default Estate;
