import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { authLoginCall } from "../loginCall";
import { useNavigate } from "react-router-dom";

const GoogleAuth = () => {
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = window.location.href.split("?");
    const params = urlParams[1];
    authLoginCall(params, dispatch);
  });
  navigate("/signin");
  return <div></div>;
};

export default GoogleAuth;
