import * as THREE from 'three';
import config from '../config'
import { Modes } from '../utils';

class AnnnotationMarker extends THREE.Sprite { 
    constructor({
        setComment, 
        setCurrMode,
        currModeRef,
        markersBuffer,
        sceneRef,
        lastSelectedMarker,
        annotation_metadata = null
      }) { //
            
      let spriteMaterial = new THREE.SpriteMaterial({
                map: new THREE.TextureLoader().load(
                  config.marker_url
                )
              });
      super(new THREE.SpriteMaterial(spriteMaterial))
      this.scale.set(config.marker_size, config.marker_size, 1);
      this.description = annotation_metadata ? annotation_metadata.description : ""
      const pos = annotation_metadata ? annotation_metadata.position : {x:0, y:0,z:0}
      this.position.set(pos.x, pos.y, pos.z)

      // Paranorm viewer state variables
      this.lastSelectedMarker = lastSelectedMarker
      this.setCurrMode = setCurrMode
      this.currModeRef = currModeRef
      this.setComment = setComment
      this.sceneRef = sceneRef
      this.markersBuffer = markersBuffer

    }

    onClickMarker(){
      this.lastSelectedMarker.current = this
      this.setUserMode(Modes.EditingAnnotation)
      if (this.description!==""){
        this.setComment(this.description)
      }
    }

    addToWorld(init = false){
      this.sceneRef.current.add(this)
      this.markersBuffer.current = [...this.markersBuffer.current, this];
      if(!init){
        this.setUserMode(Modes.EditingAnnotation)
      }
    }

    setUserMode(mode){
      this.currModeRef.current = mode
      this.setCurrMode(mode)
    }

    removeFromWorld() {
      if (this.sceneRef.current) {
        this.sceneRef.current.remove(this);
      }
      this.markersBuffer.current = this.markersBuffer.current.filter(marker => marker !== this);
    }

}

export default AnnnotationMarker