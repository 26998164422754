import { Add, Settings } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
// import styled from "styled-components";
import Estate from "../components/Estate";
// import { mobile } from "../responsive";
import NewProject from "../components/NewProject";
import axios from "axios";
import Loader from "../components/Loader";
// import { AuthContext } from "../context/authContext";
import { Link } from "react-router-dom";
import TopBar from "../components/TopBar";

// const Container = styled.div`
//   height: 100vh;
//   width: 100%;
//   display: flex;
//   justify-content: center;

//   ${mobile({
//   display: "block",
// })}
// `;

// const HomeContainer = styled.div`
//   padding: 20px;
//   width: 65vw;
//   position: relative;

//   ${mobile({
//   width: "100%",
// })}
// `;

// const Estates = styled.div`
//   padding: 10px;
//   margin-top: 20px;
// `;

// const Title = styled.div`
//   font-weight: 400;
//   font-size: 20px;
//   font-family: "Lexend", sans-serif;
// `;

// const EstateContainer = styled.div``;

// const NewProjectContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   cursor: pointer;
//   width: fit-content;
// `;

// const Icon = styled.div`
//   height: 24px;
//   width: 24px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-right: 10px;
// `;

// const Text = styled.p`
//   font-weight: ${(props) => props.$fw};
//   font-family: "Lexend", sans-serif;
//   color: ${(props) => props.color};
// `;

// const SettingsContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   padding: 10px;
// `;

const Homepage = () => {
  // component state
  const [newProject, setNewProject] = useState(false);
  const [projects, setProjects] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  // const { user } = useContext(AuthContext);

  // api endpoints
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrl2 = process.env.REACT_APP_BASE_URL2;
  const endPoint = "/projects";
  const linkEndPoint = "/reports";

  // get the projects
  useEffect(() => {
    const getProjects = async () => {
      try {
        const res = await axios.get(`${baseUrl2}${endPoint}`, {
          headers: { "ngrok-skip-browser-warning": 1 },
        });
        setProjects(res.data);
        setDataLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };

    getProjects();
  }, [baseUrl, baseUrl2]);

  // show the new project container
  const showNewProject = () => {
    setNewProject(true);
  };
  return (
    <div className="w-full h-screen md:flex md:justify-center">
      {newProject && (
        <NewProject newProject={newProject} setNewProject={setNewProject} />
      )}
      <div className="relative w-full pb-[193px] md:w-[65vw] bg-background">
        <TopBar home />
        {dataLoaded ? (
          <div>
            <div>
              {projects.map((project) => (
                <Link
                  key={project.guid}
                  to={`${linkEndPoint}`}
                  style={{ textDecoration: "none" }}
                >
                  <Estate
                    img={project.image}
                    name={project.name}
                    desc={project.location}
                  />
                </Link>
              ))}
            </div>
            <div
              className="fixed z-40 flex items-center justify-center p-2 text-white bg-secondary min-h-10 min-w-[131px] bottom-16 right-8 rounded-[10px]"
              style={{ fontFamily: "Lexend, sans-serif" }}
              onClick={showNewProject}
            >
              <div className="flex items-center justify-center">
                <Add />
              </div>
              <p className="m-0 text-[17px] font-light" style={{ fontFamily: "Lexend, sans-serif" }}>
                New Projects
              </p>
            </div>
          </div>
        ) : (
          <Loader />
        )}
        <div className="flex items-center justify-start w-full min-h-[71px] px-[5%] py-2.5 mt-12  bg-white">
          <div className="h-[25px] w-[25px] flex items-center justify-center mr-2.5">
            <Settings sx={{ color: "#000000" }} />
          </div>
          <p className="text-[17px] font-normal text-black" >
            Settings
          </p>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
