import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import styled from "styled-components";
import PDFFile from "../components/PdfFile";
// import Drawing from "../components/Drawing";
import { mobile } from "../responsive";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mobile({
  display: "block",
})}
`;

const PDFContainer = styled.div`
  height: 100vh;
  width: 65vw;
  /* overflow: hidden; */

  ${mobile({
  width: "100%",
  overflow: "scroll",
})}
`;

const PDFViewer = () => {
  // component declaratives
  const { id } = useParams();
  const [pdf, setPdf] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const location = useLocation();
  const [endPoint, setEndPoint] = useState("");

  // api endpoints
  const baseUrl = process.env.REACT_APP_BASE_URL2;
  // const endPoint = "/drawings/";

  // check for location
  useEffect(() => {
    if (location.pathname.includes("/drawings/")) {
      setEndPoint("/drawings/");
    } else if (location.pathname.includes("/ncr/")) {
      setEndPoint("/ncr/");
    } else if (location.pathname.includes("/qa_qc/")) {
      setEndPoint("/qa_qc/");
    }
  }, [location.pathname]);

  // get pdf file
  useEffect(() => {
    const getPdf = async () => {
      if (endPoint) {
        try {
          const res = await axios.get(`${baseUrl}${endPoint}${id}`);
          setPdf(res.data);
          setDataLoaded(true);
        } catch (error) {
          console.log(error);
        }
      }
    };

    getPdf();
  }, [baseUrl, endPoint, id]);

  return (
    <>
      {dataLoaded ? (
        <Container>
          <PDFContainer>
            <PDFFile pdfUrl={pdf} />
          </PDFContainer>
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default PDFViewer;
