import React, { useState } from "react";
import { Add, ArrowDropDown, ArrowDropUp, Folder, FolderOpen, InsertDriveFile } from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion"
// import NewComment from "./NewFile";

// const Folder = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 10px;
//   margin-bottom: 10px;
// `;

// const ImageContainer = styled.div`
//   height: 130px;
//   width: 150px;
// `;

// const Image = styled.img`
//   height: 100%;
//   width: 100%;
//   object-fit: cover;
// `;

// const Text = styled.p`
//   font-weight: 300;
//   font-size: 12px;
//   font-family: "Lexend", sans-serif;
//   margin: 0;
//   color: #494848;
// `;

const FolderItem = ({ folder, comments, plans, pow, setAddFile }) => {
  const [isOpen, setIsOpen] = useState(false)

  // toggle isOpen
  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  }
  return (
    <>
      <div
        className="flex items-center justify-between w-full min-h-[70px] p-5 bg-white"
        onClick={toggleIsOpen}
      >
        <div className="flex items-center">
          <div className="flex items-center justify-center w-8 h-8 mr-5">
            {/* <img className="object-cover w-full h-full" src={folderImg} alt="" /> */}
            {isOpen ? <FolderOpen fontSize="large" /> : <Folder fontSize="large" />}
          </div>
          <p className="m-0 text-[15px] font-light text-black" style={{ fontFamily: "Lexend, sans-serif" }}>
            {folder.name}
          </p>
        </div>
        <div className="flex items-center justify-center">
          {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="overflow-hidden"
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <motion.div
              initial={{ y: "-100%" }}
              animate={{ y: isOpen ? "0" : "-100%" }}
              transition={{ duration: 0.3 }}
              className="relative"
            >
              {comments &&
                <>
                  <div className="flex flex-col w-full pl-16 pr-5 py-2.5 border-[0.5px] border-white">
                    <p className="m-0 text-base font-light text-black" style={{ fontFamily: "Lexend, sans-serif" }}>
                      Bent Living room wall
                    </p>
                    <p className="m-0 text-sm font-light text-[#a6a4a4]" style={{ fontFamily: "Lexend, sans-serif" }}>
                      Wall by living room not leveled
                    </p>
                  </div>
                  <div
                    className="flex items-center w-full px-5 py-2.5 border-[0.5px] border-white text-[#9c9c9c]"
                    onClick={() => setAddFile(true)}
                  >
                    <div className="flex items-center justify-center w-8 h-8 mr-2.5 border-[0.5px] border-dashed border-[#9c9c9c]">
                      <Add />
                    </div>
                    <p className="m-0 text-base font-light" style={{ fontFamily: "Lexend, sans-serif" }}>
                      Add a comment
                    </p>
                  </div>
                </>
              }
              {plans &&
                <>
                  <div className="flex items-center w-full min-h-[70px] px-5 py-2.5 border-[0.5px] border-white">
                    <div className="flex items-center justify-center w-8 h-8 mr-2.5">
                      <InsertDriveFile />
                    </div>
                    <p className="m-0 text-base font-light text-black" style={{ fontFamily: "Lexend, sans-serif" }}>
                      File 1
                    </p>
                  </div>
                  <div className="flex items-center w-full min-h-[70px] px-5 py-2.5 border-[0.5px] border-white">
                    <div className="flex items-center justify-center w-8 h-8 mr-2.5">
                      <InsertDriveFile />
                    </div>
                    <p className="m-0 text-base font-light text-black" style={{ fontFamily: "Lexend, sans-serif" }}>
                      File 2
                    </p>
                  </div>
                  <div
                    className="flex items-center w-full min-h-[70px] px-5 py-2.5 border-[0.5px] border-white text-[#9c9c9c]"
                    onClick={() => setAddFile(true)}
                  >
                    <div className="flex items-center justify-center w-8 h-8 mr-2.5 border-[0.5px] border-dashed border-[#9c9c9c]">
                      <Add />
                    </div>
                    <p className="m-0 text-base font-light" style={{ fontFamily: "Lexend, sans-serif" }}>
                      Add new plan
                    </p>
                  </div>
                </>
              }
              {pow &&
                <>
                  <div className="flex flex-col w-full pl-16 pr-5 py-2.5 border-[0.5px] border-white">
                    <p className="m-0 text-base font-light text-black" style={{ fontFamily: "Lexend, sans-serif" }}>
                      Site clearing
                    </p>
                    <p className="m-0 text-sm font-light text-[#a6a4a4]" style={{ fontFamily: "Lexend, sans-serif" }}>
                      2 days. Mon 13-08-24
                    </p>
                  </div>
                  <div className="flex flex-col w-full pl-16 pr-5 py-2.5 border-[0.5px] border-white">
                    <p className="m-0 text-base font-light text-black" style={{ fontFamily: "Lexend, sans-serif" }}>
                      Site layout setup
                    </p>
                    <p className="m-0 text-sm font-light text-[#a6a4a4]" style={{ fontFamily: "Lexend, sans-serif" }}>
                      2 days. Fri 17-06-24
                    </p>
                  </div>
                  <div className="flex flex-col w-full pl-16 pr-5 py-2.5 border-[0.5px] border-white">
                    <p className="m-0 text-base font-light text-black" style={{ fontFamily: "Lexend, sans-serif" }}>
                      Site access
                    </p>
                    <p className="m-0 text-sm font-light text-[#a6a4a4]" style={{ fontFamily: "Lexend, sans-serif" }}>
                      12 days. Fri 16-06-24
                    </p>
                  </div>
                  <div className="flex flex-col w-full pl-16 pr-5 py-2.5 border-[0.5px] border-white">
                    <p className="m-0 text-base font-light text-black" style={{ fontFamily: "Lexend, sans-serif" }}>
                      Finalize initial SU materials procurement
                    </p>
                    <p className="m-0 text-sm font-light text-[#a6a4a4]" style={{ fontFamily: "Lexend, sans-serif" }}>
                      3 days. Fri 16-06-24
                    </p>
                  </div>
                  <div
                    className="flex items-center w-full px-5 py-2.5 border-[0.5px] border-white text-[#9c9c9c]"
                    onClick={() => setAddFile(true)}
                  >
                    <div className="flex items-center justify-center w-8 h-8 mr-2.5 border-[0.5px] border-dashed border-[#9c9c9c]">
                      <Add />
                    </div>
                    <p className="m-0 text-base font-light" style={{ fontFamily: "Lexend, sans-serif" }}>
                      Add a new program of work
                    </p>
                  </div>
                </>
              }
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default FolderItem;
