import React, { useState } from "react";
// import styled from "styled-components";
// import { mobile } from "../responsive";
// import Drawing from "../components/Drawing";
import TopBar from "../components/TopBar";
import Comment from "../components/Comment";

// const Container = styled.div`
//   height: 100%;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: relative;

//   ${mobile({
//     display: "block",
// })}
// `;

// const Background = styled.div`
//   position: fixed;
//   width: 100vw;
//   height: 100vh;
//   background-color: #00000048;
//   z-index: 99;
// `;

// const ReportsContainer = styled.div`
//   width: 65vw;
//   position: relative;

//   ${mobile({
//     width: "100%",
// })}
// `;

// const ReportSection = styled.div`
//   margin-top: 15px;
// `;

const Program = () => {
    const [newFolder, setNewFolder] = useState(false);
    return (
        <div className="relative w-full h-full md:flex md:items-center md:justify-center">
            {newFolder && <div className="fixed w-screen h-screen bg-[#00000048] z-[25]" />}
            <div className="w-full relative md:w-[65vw]">
                <TopBar />
                <div>
                    <Comment newFolder={newFolder} setNewFolder={setNewFolder} pow />
                </div>
            </div>
        </div>
    );
};

export default Program;
