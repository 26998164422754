const PreviewSceneItem = ({ src, onClick, isSelected }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <img
      src={src}
      alt="thumbnail"
      className={`w-52 h-52 object-cover ${
        isSelected
          ? "border border-solid border-primary"
          : "border border-solid border-transparent"
      }`}
      onClick={handleClick}
    />
  );
};

export default PreviewSceneItem;
