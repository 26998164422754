import { Add, Delete } from "@mui/icons-material";
import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  min-width: 160px;
  height: 129px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dotted #000;
  border-radius: 7px;
  cursor: pointer;
  position: relative;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lexend", sans-serif;
  font-weight: 300;
  font-size: 15px;
  color: #757575;
`;

const AddContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  color: #020202;
`;

const Text = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadContainer = styled.div`
  position: absolute;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const ReportContainer = styled.div`
  width: 300px;
  padding: 10px 10px 10px 10px;
  background-color: white;
  border-radius: 20px;
  z-index: 9999;
  font-family: "Lexend", sans-serif;
  box-shadow: 12px 10px 32px 2px rgba(0, 0, 0, 0.7);
`;

const Title = styled.h3`
  text-align: center;
  font-weight: 500;
  color: #1e73bd;
`;

const InputContainer = styled.div`
  width: 100%;
`;
const Input = styled.input``;

const NameInput = styled(Input)`
  width: 100%;
  font-size: 15px;
  padding: 10px 10px 3px 10px;
  border: none;
  border-bottom: 1px solid black;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 15px;
  }
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`;

const BottomText = styled.p`
  flex: 1;
  text-align: center;
  color: ${(props) => props.$color};
  margin: 0;
  border-left: ${(props) => (props.$right ? "1px solid #a7a7a7" : "none")};
  font-weight: 400;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.label`
  background-color: #1e73bd;
  color: white;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
`;

const DeleteFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadReport = ({ newReport, setNewReport, setReports }) => {
  // component states
  const [file, setFile] = useState(null);
  const [name, setName] = useState("")

  // api endpoints
  const baseUrl = process.env.REACT_APP_BASE_URL2;
  const endPoint = "/reports";

  const hideNewReport = () => {
    setNewReport(false);
  };

  const getReports = async () => {
    try {
      const res = await axios.get(`${baseUrl}${endPoint}`);
      console.log(res.data);
      setReports(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const createReport = async () => {
    const data = new FormData();
    data.append("name", name)
    data.append("picture", file);
    try {
      const res = await axios.post(`${baseUrl}${endPoint}`, data, {
        headers: {
          "ngrok-skip-browser-warning": 1,
        },
      });
      console.log(res.data);
      getReports();
      setNewReport(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <UploadContainer>
      <ReportContainer>
        <Title>New Report</Title>
        <InputContainer>
          <NameInput
            type="text"
            placeholder="Report Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </InputContainer>
        <InputContainer
          style={{
            marginTop: "20px",
            fontWeight: "300",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: "20px" }}>
            {file ? file.name : "Report Picture"}
          </span>
          <LabelContainer>
            <Label htmlFor="file">
              Upload
              <Input
                type="file"
                id="file"
                style={{ display: "none" }}
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Label>
            {file && (
              <DeleteFile
                onClick={() => {
                  setFile(null);
                }}
              >
                <Delete sx={{ color: "red" }} />
              </DeleteFile>
            )}
          </LabelContainer>
        </InputContainer>
        <BottomContainer>
          <BottomText $color="red">
            <span style={{ cursor: "pointer" }} onClick={hideNewReport}>
              Cancel
            </span>
          </BottomText>
          <BottomText $color="#1E73BD" $right>
            <span style={{ cursor: "pointer" }} onClick={createReport}>
              Confirm
            </span>
          </BottomText>
        </BottomContainer>
      </ReportContainer>
    </UploadContainer>
  );
};

const NewReport = ({ newReport, setNewReport, setReports }) => {
  // component states
  // const [newReport, setNewReport] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleRecord = () => {
    // Record logic goes here
    setShowModal(false);
  };
 

  return (
    <>
      {newReport && (
        <UploadReport
          newReport={newReport}
          setNewReport={setNewReport}
          setReports={setReports}
        />
      )}
      
      {showModal && (
        <div className="fixed z-20 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-[7px] max-w-[293px] w-full">
          <div className="p-5">
          <p className="text-center mb-2 font-light">New Space</p>
              <select className="border border-gray-300 w-full h-[30px] rounded-[7px] px-1 text-[15px]"
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}>
                   <option value="">Dimension Size</option>
                  <option value="Linear Dimensions">Linear Dimensions</option>
                  <option value="Radial Dimensions">Radial Dimensions</option>
                  <option value="Ordinate Dimensions">Ordinate Dimensions</option>
                  <option value="Arc Length Dimensions">Arc Length Dimensions</option>
                </select>
          </div>
            <div className="flex w-full">
            <button
              className="bg-[#D9D9D9] text-[#DB0707] w-3/6 rounded-es-[7px] font-light px-4 py-2"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="bg-[#333333] w-3/6 rounded-ee-[7px] text-white font-light px-4 py-2"
              onClick={handleRecord}
            >
              Record
            </button>
            </div>
          </div>
        </div>
      )}
      
      <div style={{ flex: "1", maxWidth: "100%" }}>
      <div className="flex flex-col gap-[10px] new-space" onClick={() => setShowModal(true)}>
        <Container>
          <TextContainer>
            <AddContainer>
              <Add  />
            </AddContainer>
            {/* <Text>
              <span>New Report</span>
            </Text> */}
          </TextContainer>
        </Container>
        <p className="text-center text-[12px] text-[#494848] font-light">New space</p>
        </div>
      </div>
    </>
  );
};

export default NewReport;
