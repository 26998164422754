import * as THREE from 'three';
import config from '../config'

class FloorPlane extends THREE.Mesh {
    constructor(sceneRef) {
      const planeMaterial = new THREE.MeshBasicMaterial({
        color: 'red',
        transparent: true,
        opacity: 0.0
      });
      const planeGeometry = new THREE.PlaneGeometry(
        config.floorPlaneSize, config.floorPlaneSize);
      super(planeGeometry, planeMaterial);
  
      this.position.set(-50, -50, -20);
      this.rotation.x = -Math.PI / 2;
  
      this.sceneRef = sceneRef;
    }

    addToScene(){
        this.sceneRef.current.add(this);
    }
}
export default FloorPlane
