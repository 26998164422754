import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import { autoDeskLogincall } from "../loginCall";

const AutoDeskAuth = () => {
  const { dispatch } = useContext(AuthContext);
  const navigte = useNavigate();
  useEffect(() => {
    const urlParams = window.location.href.split("?");
    const params = urlParams[1];
    autoDeskLogincall(params, dispatch);
  });
  navigte("/signin");

  return <div></div>;
};

export default AutoDeskAuth;
