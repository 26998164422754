import { ArrowBackIosNew } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
// import image from "../media/Rectangle 2.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PannellumViewer from "../components/Pannellum";
import Loader from "../components/Loader";
import PanoramaViewer from "../components/PanoramaViewer";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mobile({
  display: "block",
})}
`;

const PanoramaContainer = styled.div`
  width: 60vw;
  height: 100vh;
  position: relative;

  ${mobile({
  width: "100%",
})}
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 40px;
  left: 20px;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
`;

const LoaderContainer = styled.div`
  width: 60vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mobile({
  width: "100%",
})}
`;

const Panorama = () => {
  const { id } = useParams();
  const [report, setReport] = useState({});
  const [hotspot, setHotspot] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const navigate = useNavigate();

  // api endpoints
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrl2 = process.env.REACT_APP_BASE_URL2;
  const endPoint = "/reports/";

  useEffect(() => {
    const getReport = async () => {
      try {
        const res = await axios.get(`${baseUrl2}${endPoint}${id}`, {
          headers: { "ngrok-skip-browser-warning": 1 },
        });
        console.log(res.data);
        setReport(res.data);
        setHotspot(res.data.hotspots);
        setTimeout(setDataLoaded(true), 2000);
      } catch (error) {
        console.log(error);
      }
    };

    getReport();
  }, [baseUrl, baseUrl2, id]);
  return (
    <div className="items-center justify-center w-full h-full md:flex">
      {dataLoaded ? (
        <div className="relative w-screen h-screen">
          <div
            className="absolute z-30 w-6 h-4 flexCenter top-10 left-5"
            onClick={() => {
              navigate("/reports");
            }}
          >
            <ArrowBackIosNew sx={{ color: "#424242" }} />
          </div>
          <div className="relative top-0 w-full h-full">
            {/* <PannellumViewer
              img={report.scenes[1].panorama}
              scenes={report.scenes}
              hotspot={hotspot}
              setHotspot={setHotspot}
              id={id}
            /> */}
            <PanoramaViewer />
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flexCenter md:w-[60vw]">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Panorama;
