import {isMobileAndTablet} from "./utils";

const config = {
  admin: !isMobileAndTablet(),
  marker_size: 30,
  world_radius: 500,
  window_width_scale: 1,
  sensitivity: 0.15,
  marker_url: "https://cywarr.github.io/small-shop/Marker.png",
  //'marker_url' :  "https://res.cloudinary.com/hitmeeup/image/upload/v1715428675/nu2sq7uugq7qyzzhgflr.jpg",
  nav_ring_color: "black",
  preview_nav_ring_color: "green",
  delete_nav_ring_color : "red",
  useSliderAddNav: false,
  floorPlaneSize: 500,
  moveNavRingoffset: 0.3,
};

export default config;
