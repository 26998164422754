import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ImageContainer = styled.div`
  position: relative;
  height: 129px;
  min-width: 160px;
  ${'' /* max-width: 150px; */}
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 7px;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.75);
`;

const LabelsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  gap: 10px;
`;

const Label = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${(props) => props.$bg};
  border-radius: 50%;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const Name = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  font-family: "Lexend", sans-serif;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 12px;
  margin: 0;
  color: #494848;
`;

const ReportItem = ({ r }) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [nonConformity, setNonConformity] = useState(false);
  const [comment, setComment] = useState(false);
  const [changeOrder, setChangeOrder] = useState(false);
  const nonConformityColor = "#fb1616";
  const commentColor = "#fbe30d";
  const changeOrderColor = "#fb9f16";

  // set date and labels
  useEffect(() => {
    // set and show annotation labels
    const checkLabels = () => {
      if (r.hotspots.includes(nonConformityColor)) {
        setNonConformity(true);
      }
      if (r.hotspots.includes(commentColor)) {
        setComment(true);
      }
      if (r.hotspots.includes(changeOrderColor)) {
        setChangeOrder(true);
      }
    };

    // get date and time
    const getDateAndTime = () => {
      const dateString = r.date_created;
      const dateAndTime = new Date(dateString);

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const formattedDate = dateAndTime.toLocaleDateString("en-US", options);
      const [formattedDatePart, formattedYearPart, formattedTimePart] =
        formattedDate.split(", ");
      setDate(`${formattedDatePart}, ${formattedYearPart}`);
      setTime(formattedTimePart);
    };

    // call functions
    getDateAndTime();
    checkLabels();
  }, [r.date_created, r.hotspots]);

  return (
    <div>
      <ImageContainer>
        <Image src={r.image} />
        <LabelsContainer>
          {nonConformity && <Label $bg={nonConformityColor} />}
          {comment && <Label $bg={commentColor} />}
          {changeOrder && <Label $bg={changeOrderColor} />}
        </LabelsContainer>
      </ImageContainer>
      <Details>
        <Name>{r.name}</Name>
        <Text>{date}</Text>
        <Text>{time}</Text>
      </Details>
    </div>
  );
};

export default ReportItem;
