import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReportItem from "./ReportItem";
import axios from "axios";
import NewReport from "./NewReport";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";

const Container = styled.div`
  display: flex;
  ${'' /* padding: 20px; */}
  /* align-items: center; */
  ${'' /* flex-wrap: wrap; */}
  /* gap: 30px; */
  margin-top: 30px;
`;

const ReportContainer = styled.div`
  flex: 1;
  max-width: 100%;
`;

const Report = ({ newReport, setNewReport }) => {
  // component state
  const [reports, setReports] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  // api endpoints
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrl2 = process.env.REACT_APP_BASE_URL2;
  const endPoint = "/reports";

  // get reports
  useEffect(() => {
    const getReports = async () => {
      try {
        const res = await axios.get(`${baseUrl2}${endPoint}`, {
          headers: { "ngrok-skip-browser-warning": 1 },
        });
        console.log(res.data);
        setReports(res.data);
        setDataLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };

    getReports();
  }, [baseUrl, baseUrl2]);

  return (
    <>
      {dataLoaded ? (
        <div className="mt-8 grid grid-cols-2 ss:grid-cols-3 sm:grid-cols-4 gap-[5%] px-[5%]">
          <NewReport
            newReport={newReport}
            setNewReport={setNewReport}
            setReports={setReports}
          />
          {reports.map((report, index) => (
            <Link
              key={report.guid}
              to={`${endPoint}/${report.guid}`}
              style={{ textDecoration: "none", flex: "1", maxWidth: "100%" }}
            >
              <ReportContainer>
                <ReportItem r={report} />
              </ReportContainer>
            </Link>
          ))}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Report;
