import * as THREE from 'three';
import config from '../config'
import NavRing from '../customSceneObjects/NavRing';

export class DesktopEventListenersUtil  { 
    constructor({
        cameraRef, 
        raycasterRef,
        lastSelectedMarker, 
        lastHoveredRingRef,
        addNavSceneidx, 
        navigationRingsBuffer,
        floorPlaneRef,
        previewRingRef,
        lon, 
        lat,
        onPointerDownMouseX, onPointerDownMouseY,
        onPointerDownLon, onPointerDownLat,
        setComment,
        markersBuffer,
        sceneRef,
        setCurrMode,
        currModeRef,
        setCurrentImageIndex
    }) {
        this.currModeRef = currModeRef

        this.cameraRef = cameraRef;
        this.raycasterRef = raycasterRef;
        this.lastSelectedMarker = lastSelectedMarker;
        this.lastHoveredRingRef = lastHoveredRingRef
        this.addNavSceneidx = addNavSceneidx
        this.navigationRingsBuffer = navigationRingsBuffer
        this.floorPlaneRef = floorPlaneRef
        this.previewRingRef = previewRingRef
        this.lon = lon
        this.lat = lat
        this.onPointerDownMouseX = onPointerDownMouseX
        this.onPointerDownMouseY = onPointerDownMouseY
        this.onPointerDownLon = onPointerDownLon
        this.onPointerDownLat  = onPointerDownLat
        this.setComment = setComment
        this.markersBuffer = markersBuffer
        this.sceneRef = sceneRef
        this.setCurrMode = setCurrMode
        this.setCurrentImageIndex = setCurrentImageIndex
    }

    navRingFollowCursor(event) {
        const floorIntersects = this.getMouseIntersectionOnFloor(event)
        if (floorIntersects.length === 0) {
            console.log("NO floor hits")
            return;
        }
    
        const pos = floorIntersects[0].point;
        // if instance of preview ring doesnt exist create one
        if (!this.previewRingRef.current) { 
            console.log("Created first")
            
            let previewRing = new NavRing({
                currPreviewRingObj : this.previewRingRef,
                lastHoveredNavigationObj: this.lastHoveredRingRef,
                navigationRingsBuffer : this.navigationRingsBuffer,
                currModeRef: this.currModeRef,
                setCurrMode: this.setCurrMode,
                setCurrentImageIndex :this.setCurrentImageIndex,
                nav_point_idx : this.addNavSceneidx,
                sceneRef: this.sceneRef,
                navigation_metadata: {
                    "position": pos,
                    "xRotation": 269,
                    "opacity": 0.9
                }, 
                isPreview : true})
            previewRing.addPreviewToWorld()
        } else {
            console.log("preview supposedly in memory")
            this.previewRingRef.current.moveTo(pos);
        }
    }

    getMouseIntersectionOnFloor(event) {
        const scaledWindowWidth = window.innerWidth * config.window_width_scale;
        const mouseVector = new THREE.Vector2(
            (event.clientX / scaledWindowWidth) * 2 - 1,
            -(event.clientY / window.innerHeight) * 2 + 1
        );
        this.raycasterRef.current.setFromCamera(mouseVector, this.cameraRef.current);
        return this.raycasterRef.current.intersectObject(this.floorPlaneRef.current);
    }

    getMouseObjectsIntersects(event, candidate_objects, _x_value = null, _y_value = null) {
        const scaledWindowWidth = window.innerWidth * config.window_width_scale;
    
        const x_value = _x_value || event.clientX;
        const y_value = _y_value || event.clientY;
    
        var mouseVector = new THREE.Vector2(
            x_value / scaledWindowWidth * 2 - 1, 
            -(y_value / window.innerHeight) * 2 + 1)
    
        this.raycasterRef.current.setFromCamera(mouseVector, this.cameraRef.current);
        
        return this.raycasterRef.current.intersectObjects(candidate_objects);
    }

    

    handleMarkerDetection(event) { 
    
        const markerIntersects = this.getMouseObjectsIntersects(event, this.markersBuffer.current, this.onPointerDownMouseX, this.onPointerDownMouseY);

        if (markerIntersects.length > 0) {
            if (this.ifMarkerCancel(markerIntersects)){
                markerIntersects[0].object.removeFromWorld()
            }else{
                markerIntersects[0].object.onClickMarker();
            }
            
            return true
        }
        return false
    }

    ifMarkerCancel(intersects){
        let obj = intersects[0];
        let uv = obj.uv;
        return Math.min(uv.x, uv.y) > 0.75
    } 

    handleNavPointDirectNavigation(event) {
        const NavRingIntersects =   this.getMouseObjectsIntersects( event, this.navigationRingsBuffer.current, )
        if (NavRingIntersects.length > 0){
            NavRingIntersects[0].object.onNavigate();
        }
    }
    
    
    handleNavPointCreation(event) {
        if (this.previewRingRef.current) {
            this.previewRingRef.current.placePreviewNavPoint();
        } 
       this.sceneRef.current.remove(this.floorPlaneRef.current);
       this.addNavSceneidx.current = null;
    }
    
}