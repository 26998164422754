import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import styled, { keyframes } from "styled-components";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import { mobile } from "../responsive";
import AddNew from "../components/AddNew";
import Loader from "../components/Loader";
import { ArrowBackIosNew } from "@mui/icons-material";
import FolderItem from "../components/FolderItem";
import PdfItem from "../components/PdfItem";

// const Container = styled.div`
//   height: 100vh;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   position: relative;

//   ${mobile({
//   display: "block",
// })}
// `;
// const Background = styled.div`
//   position: fixed;
//   width: 100vw;
//   height: 100vh;
//   background-color: #00000048;
//   z-index: 99;
// `;

// const Container2 = styled.div`
//   padding: 20px;
//   width: 65vw;
//   position: relative;

//   ${mobile({
//   width: "100%",
// })}
// `;

// const BackContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   padding: 10px 0;
//   gap: 3px;
// `;

const slider = keyframes`
  0%{
    transform: translateX(0);
  }
  75%{
    transform: translateX(-10px);
  }
  90%{
    transform: translateX(-7.5px)
  }
  100%{
    transform: translateX(-10px);
  }
`;

const BackIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  animation: ${slider} 0.5s ease-out 0.8ms;
  animation-fill-mode: forwards;
`;

// const BackText = styled.div`
//   /* color: #0073bd; */
//   font-weight: 400;
//   font-family: "Lexend", sans-serif;
//   font-size: 18px;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// `;

// const FolderContainer = styled.div`
//   display: flex;
//   /* align-items: center; */
//   flex-wrap: wrap;
//   /* gap: 30px; */
//   margin-top: 30px;
// `;

const FolderViewer = () => {
  // component declaratives
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [folders, setFolders] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const location = useLocation();
  const [endPoint, setEndPoint] = useState("");
  const [addNewItem, setAddNewItem] = useState(false);
  const [folderName, setFolderName] = useState("Folder");
  const navigate = useNavigate();

  // api endpoints
  const baseUrl = process.env.REACT_APP_BASE_URL2;

  // check for location
  useEffect(() => {
    if (location.pathname.includes("/drawings/")) {
      setEndPoint("/drawings/");
    } else if (location.pathname.includes("/ncr/")) {
      setEndPoint("/ncr/");
    } else if (location.pathname.includes("/qa_qc/")) {
      setEndPoint("/qa_qc/");
    }
  }, [location.pathname]);

  // get Data
  useEffect(() => {
    const getData = async () => {
      if (endPoint) {
        try {
          const res = await axios.get(`${baseUrl}${endPoint}${id}`);
          setData(res.data);
          setFolderName(res.data.name);
          console.log(res.data);
          setDataLoaded(true);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getData();
  }, [baseUrl, endPoint, id]);

  // split data into files and folders
  useEffect(() => {
    if (data) {
      const dataFolders = data.files.filter((d) => !d.is_file);
      const dataPdfs = data.files.filter((d) => d.is_file);
      setFolders(dataFolders);
      setPdfs(dataPdfs);
    }
  }, [data]);

  // navigate to previous page
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="relative items-center justify-center w-full h-screen md:flex">
      {addNewItem && <div className="sticky w-screen h-screen bg-[#00000048] z-20 top-0 left-0" />}
      <div className="w-full md:w-[65vw] relative">
        <TopBar />
        <div className="flex items-center justify-start p-2.5 gap-[3px]">
          <BackIcon onClick={goBack}>
            <ArrowBackIosNew sx={{ color: "#333333" }} />
          </BackIcon>
          <div
            className="overflow-hidden text-lg font-normal whitespace-nowrap text-ellipsis"
            style={{ fontFamily: "Lexend, sans-serif" }}
          >
            {folderName}
          </div>
        </div>
        {dataLoaded ? (
          <div className="flex flex-wrap mt-5">
            <AddNew
              addNewItem={addNewItem}
              setAddNewItem={setAddNewItem}
              setData={setData}
              id={id}
            />
            {folders.map((folder, index) => (
              <Link
                key={folder.guid}
                to={`${endPoint}folder/${folder.guid}`}
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  flex: "1",
                  maxWidth: "100%",
                }}
              >
                <FolderItem folder={folder} />
              </Link>
            ))}
            {pdfs.map((pdf, index) => (
              <Link
                key={pdf.guid}
                to={`${endPoint}pdf/${pdf.guid}`}
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  flex: "1",
                  maxWidth: "100%",
                }}
              >
                <PdfItem file={pdf} />
              </Link>
            ))}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default FolderViewer;
