import axios from "axios";
// import { useNavigate } from "react-router-dom";

// const baseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl2 = process.env.REACT_APP_BASE_URL2;
const endPoint1 = "/login";
const googleEndpoint = "/google/callback";
const autodeskEndpoint = "/autodesk/callback";

const loginCall = async (userCredentials, dispatch) => {
  dispatch({ type: "LOGIN_START" });
  try {
    const res = await axios.post(`${baseUrl2}${endPoint1}`, userCredentials, {
      headers: { "ngrok-skip-browser-warning": 1 },
    });
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: res.data,
    });
    console.log(res);
  } catch (error) {
    dispatch({
      type: "LOGIN_FAILURE",
      payload: error,
    });
    console.log(error);
  }
};

const authLoginCall = async (params, dispatch) => {
  dispatch({
    type: "LOGIN_START",
  });
  try {
    const res = await axios.get(`${baseUrl2}${googleEndpoint}?${params}`, {
      withCredentials: true,
    });
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: res.data,
    });
    console.log(res.data);
  } catch (error) {
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: error,
    });
  }
};

const autoDeskLogincall = async (params, dispatch) => {
  dispatch({ type: "LOGIN_START" });
  try {
    const res = await axios.get(`${baseUrl2}${autodeskEndpoint}?${params}`, {
      withCredentials: true,
    });
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: res.data,
    });
    console.log(res.data);
  } catch (error) {
    dispatch({
      type: "LOGIN_FAILURE",
      payload: error,
    });
  }
};

export { loginCall };
export { authLoginCall };
export { autoDeskLogincall };
