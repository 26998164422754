import React from "react";
import styled from "styled-components";
import pdf from "../media/PdfIcon.png";
import { ChevronRight } from "@mui/icons-material";

// const Folder = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 10px;
//   width: 150px;
//   margin-bottom: 10px;
// `;

// const ImageContainer = styled.div`
//   height: 130px;
//   width: 150px;
// `;

// const Image = styled.img`
//   height: 100%;
//   width: 100%;
//   object-fit: contain;
// `;

// const Text = styled.p`
//   font-weight: 300;
//   font-size: 12px;
//   font-family: "Lexend", sans-serif;
//   margin: 0;
//   color: #494848;
// `;

const PdfItem = ({ file }) => {
  return (
    <div className="flex items-center justify-between w-full p-5 bg-white">
      <div className="flex items-center">
        <div className="w-8 h-8 mr-5">
          <img className="object-cover w-full h-full" src={pdf} alt="" />
        </div>
        <p className="m-0 text-base font-light text-black" style={{ fontFamily: "Lexend, sans-serif" }}>{file.name}</p>
      </div>
      <div className="flex items-center justify-center">
        <ChevronRight />
      </div>
    </div>
  );
};

export default PdfItem;
