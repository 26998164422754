import * as THREE from 'three';
import config from '../config'
import { DesktopEventListenersUtil } from './desktopEventListenersUtil';
import {isMobileAndTablet, Modes} from '../utils';
import AnnnotationMarker from '../customSceneObjects/AnnotationMarker';

export class DesktopEventListeners { 
    constructor({
        cameraRef, 
        raycasterRef, 
        rendererRef,
        lastSelectedMarkerRef, 
        setCurrMode, 
        currModeRef,
        lastHoveredRingRef,
        addNavSceneidx, 
        navigationRingsBuffer,
        floorPlaneRef,
        previewRingRef,
        lon, lat,
        onPointerDownMouseX, onPointerDownMouseY,
        onPointerDownLon, onPointerDownLat,
        setComment,
        markersBuffer,
        sceneRef,
        setCurrentImageIndex
    }) {
        this.cameraRef = cameraRef;
        this.rendererRef = rendererRef
        this.raycasterRef = raycasterRef;
        this.currModeRef = currModeRef
        this.lastSelectedMarkerRef = lastSelectedMarkerRef;
        this.setCurrMode = setCurrMode;
        this.lastHoveredRingRef = lastHoveredRingRef
        this.addNavSceneidx = addNavSceneidx
        this.navigationRingsBuffer = navigationRingsBuffer
        this.floorPlaneRef = floorPlaneRef
        this.previewRingRef = previewRingRef
        this.lon = lon
        this.lat = lat
        this.onPointerDownMouseX = onPointerDownMouseX
        this.onPointerDownMouseY = onPointerDownMouseY
        this.onPointerDownLon = onPointerDownLon
        this.onPointerDownLat  = onPointerDownLat
        this.setComment = setComment
        this.markersBuffer = markersBuffer
        this.sceneRef = sceneRef

        this.util = new DesktopEventListenersUtil({ 
            cameraRef: this.cameraRef, 
            raycasterRef: this.raycasterRef, 
            lastSelectedMarker : this.lastSelectedMarkerRef, 
            lastHoveredRingRef : this.lastHoveredRingRef,
            addNavSceneidx: this.addNavSceneidx, 
            navigationRingsBuffer: this.navigationRingsBuffer,
            floorPlaneRef : this.floorPlaneRef,
            previewRingRef: this.previewRingRef,
            lon: this.lon, 
            lat: this.lat,
            onPointerDownMouseX : this.onPointerDownMouseX,
            onPointerDownMouseY: this.onPointerDownMouseY,
            onPointerDownLon: this.onPointerDownLon, 
            onPointerDownLat : this.onPointerDownLat,
            setComment: this.setComment,
            markersBuffer : this.markersBuffer,
            sceneRef : this.sceneRef,
            setCurrMode : this.setCurrMode,
            currModeRef: this.currModeRef,
            setCurrentImageIndex: setCurrentImageIndex
        })

    }

    onDocumentMouseWheel(event) {
        const fov = this.cameraRef.current.fov + event.deltaY * 0.05
        this.cameraRef.current.fov = THREE.MathUtils.clamp(fov, 10, 75);
        this.cameraRef.current.updateProjectionMatrix();
    }

    onWindowResize() {
        const scaledWindowWidth = window.innerWidth * config.window_width_scale;
        const windowHeight = window.innerHeight // compute once
        this.cameraRef.current.aspect = scaledWindowWidth / windowHeight;
        this.cameraRef.current.updateProjectionMatrix();
        this.rendererRef.current.setSize(scaledWindowWidth, windowHeight);
    }

    onPointerDown(event) {

       // In desktop we navigate if a ring has be hovered over
       if (!isMobileAndTablet() && this.lastHoveredRingRef.current !== null && this.currModeRef.current === Modes.DEFAULT ) {
           this.lastHoveredRingRef.current.onNavigate();
           return;
       }
   
       if (event.isPrimary === false) return;
       this.setComment("");

       switch(this.currModeRef.current){

            case Modes.CreateNav:
                this.util.handleNavPointCreation(event);
                break

            case Modes.DeleteNav:
                // clicking on nav ring in this mode deletes it
                const NavRingIntersects = this.util.getMouseObjectsIntersects(event, this.navigationRingsBuffer.current)
                
                if (NavRingIntersects.length > 0) {
                    NavRingIntersects[0].object.removeFromWorld();
                }
                this.currModeRef.current = Modes.DEFAULT
                break
                
            case Modes.DEFAULT:
                // in normal mode we watch for clicks of NavRings and Marker
                const markerksDetected = this.util.handleMarkerDetection(event)
                
                if (!markerksDetected &&  isMobileAndTablet()){
                    this.util.handleNavPointDirectNavigation(event)
                }
                break
            
        }

   }
    onDoubleClick(event) {
        const scaledWindowWidth = window.innerWidth * config.window_width_scale;
        const mouse_x = event.clientX / scaledWindowWidth * 2 - 1;
        const mouse_y = -(event.clientY / window.innerHeight) * 2 + 1;
        
        const mouseVector = new THREE.Vector2(mouse_x, mouse_y);
        this.raycasterRef.current.setFromCamera(mouseVector, this.cameraRef.current);
        
        let annotationMarker = new AnnnotationMarker({
            setComment: this.setComment,
            setCurrMode: this.setCurrMode,
            currModeRef: this.currModeRef,
            markersBuffer: this.markersBuffer,
            sceneRef : this.sceneRef,
            lastSelectedMarker: this.lastSelectedMarkerRef,
            annotation_metadata: null
        })
        this.lastSelectedMarkerRef.current = annotationMarker;
        
        this.raycasterRef.current.ray.at(210, annotationMarker.position);
        annotationMarker.addToWorld();

        this.currModeRef.current = Modes.EditingAnnotation
        this.setCurrMode(Modes.EditingAnnotation)
    }

    onPointerMove(event) {
        let NavRingIntersects = null
        switch(this.currModeRef.current){
            case Modes.DEFAULT:
                // In default mode we mainly want to hover over rings
                if (this.lastHoveredRingRef.current !== null) {
                    this.lastHoveredRingRef.current.onStopHover();
                }
                NavRingIntersects = this.util.getMouseObjectsIntersects(event, this.navigationRingsBuffer.current)
                if (NavRingIntersects.length > 0) {
                    NavRingIntersects[0].object.onHover();
                }
                break
            
            case Modes.CreateNav:
                if (this.addNavSceneidx.current !== null) {
                    this.util.navRingFollowCursor(event);
                }
                break

            case Modes.DeleteNav:
                if (this.lastHoveredRingRef.current !== null) {
                    this.lastHoveredRingRef.current.onStopHoverDelete();
                }

                NavRingIntersects = this.util.getMouseObjectsIntersects(event, this.navigationRingsBuffer.current)
                if (NavRingIntersects.length > 0) {
                   NavRingIntersects[0].object.onHoverDelete()
                }
                break

            default:
                // Handle unexpected mode
                //console.error(`Unexpected mode: ${this.currModeRef.current}`);
                break
        }

    }
}







////////////////////

// const longPressDuration = 800;
// let longPressDetected = false
// function startLongPressTimer(event) {
//     longPressTimer = setTimeout(() => {
//         onLongPress(event);
//     }, longPressDuration);
// }

// function
    //     onTouchStart(ev) {
    //     if (ev.targetTouches.length === 2) {
    //         // Save the initial touch points to the cache
    //         tpCache = [ev.targetTouches[0], ev.targetTouches[1]];
    //         initialDistance = getDistance(tpCache[0], tpCache[1]);
    //     }
    // }

    // function onTouchMove(ev) {
    //     if (ev.targetTouches.length === 2 && tpCache.length === 2) {
    //         // Update the cached touch points
    //         tpCache = [ev.targetTouches[0], ev.targetTouches[1]];

    //         const currentDistance = getDistance(tpCache[0], tpCache[1]);
    //         const scale = currentDistance / initialDistance;

    //         // Apply the scaling transformation
    //         ev.target.style.transform = `scale(${scale})`;

    //         // Optionally, change background color or any other indication
    //         if (scale > 1) {
    //             console.log("zoom in")
    //         } else {
    //             console.log("zoom ou") // Zoom out
    //         }
    //     }
    // }


    // function called when touch starts
    // const onTouchStart = (event) => {
    //     // check if touch is equal to one
    //     if (event.touches.length === 1) {
    //         updateLastOnPointerVariables(event)
    //         document.addEventListener('pointermove', onPointerMovePan);
    //         document.addEventListener('pointerup', onPointerUp);
    //     }

    //     // check if touch is equal to two
    //     // i.e. two fingers on the screen
    //     if (event.touches.length === 2) {
    //         initialDistance = getDistance(event.touches[0], event.touches[1])
    //         initialFov.current = camera.fov
    //         document.removeEventListener("touchmove", onPointerMovePan)
    //     }
    //     tpCache = [...event.touches]
    // }

    // // function called during touch move
    // const onTouchMove = (event) => {
    //     // check if touch is equal to two
    //     // i.e. two fingers on the screen
    //     if (event.touches.length === 2) {
    //         const distance = getDistance(event.touches[0], event.touches[1])
    //         const deltaDistance = initialDistance - distance
    //         zoom(deltaDistance)
    //     }
    // }

    // // function when touch ends
    // const onTouchEnd = (event) => {
    //     tpCache = [...event.touches];
    //     if (tpCache.length < 2) {
    //         document.addEventListener('touchmove', onPointerMovePan);
    //     }
    // }


    // function
    //     getDistance(touch1, touch2) {
    //     const dx = touch2.clientX - touch1.clientX;
    //     const dy = touch2.clientY - touch1.clientY;
    //     return Math.sqrt(dx * dx + dy * dy);
    // }

    // function
    //     onTouchEnd(event) {
    //     if (event.targetTouches.length < 2) {
    //         tpCache = [];
    //     }
    // }
    // function onPointerCancel(event) {
    //     clearTimeout(longPressTimer);
    // }

    // container.removeEventListener('touchstart', onTouchStart);
    // container.removeEventListener('touchmove', onTouchMove);
    // container.removeEventListener('touchend', onTouchEnd);

    // function onLongPress(event) {
    //     longPressDetected = true
    //     if (detectNavRing(event)){
    //         console.log("Nav ring dtected on long press")
    //         // intersects[0].object.removeFromWorld();
    //     }else if(detectMarker()){
    //         //
    //     }
    // }




    // function handleNavPointCreation(event) {
    //     if (currPreviewRing_pntr.current) {
    //         currPreviewRing_pntr.current.placePreviewNavPoint();
    //     } else {
    //         addNavPointDirectly(event);
    //         currPreviewRing_pntr.current.placePreviewNavPoint();
    //     }
    //     sceneRef.current.remove(floorPlane.current);
    //     addNavSceneidx.current = null;
    // }