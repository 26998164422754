import  AnnnotationMarker  from "./customSceneObjects/AnnotationMarker"
import NavRing from './customSceneObjects/NavRing';
import { DesktopEventListeners } from "./EventListeners/desktopEventListeners";

export class ParanomaInit   { 
    constructor({
        cameraRef,
        raycasterRef,
        rendererRef,

        setComment,
        setCurrMode,
        setCurrentImageIndex,
       
        markersBuffer,
        navigationRingsBuffer,
        
        lonRef,
        latRef,
        sceneRef,
        currModeRef,
        floorPlaneRef,
        previewRingRef,
        lastHoveredRingRef,
        lastSelectedMarkerRef,


        addNavSceneidx,
        onPointerDownMouseX,
        onPointerDownMouseY,
        onPointerDownLon,
        onPointerDownLat

        

    }){
        this.cameraRef = cameraRef
        this.raycasterRef = raycasterRef
        this.rendererRef = rendererRef

        this.setComment = setComment;
        this.setCurrMode = setCurrMode;
        this.setCurrentImageIndex = setCurrentImageIndex;
       
        this.markersBuffer = markersBuffer;
        this.navigationRingsBuffer = navigationRingsBuffer;
        
        this.lonRef = lonRef;
        this.latRef = latRef;
        this.sceneRef = sceneRef;
        this.currModeRef = currModeRef;
        this.floorPlaneRef = floorPlaneRef
        this.previewRingRef = previewRingRef;
        this.lastHoveredRingRef = lastHoveredRingRef;
        this.lastSelectedMarkerRef = lastSelectedMarkerRef;

        this.addNavSceneidx = addNavSceneidx
        this.onPointerDownMouseX = onPointerDownMouseX
        this.onPointerDownMouseY = onPointerDownMouseY
        this.onPointerDownLon = onPointerDownLon
        this.onPointerDownLat = onPointerDownLat
    }

    initialiseAnnotations(annotations) {
        for (let i = 0; i < annotations.length; i++) {
            let annotationMarker = new AnnnotationMarker({
                setComment: this.setComment,
                setCurrMode: this.setCurrMode,
                currModeRef: this.currModeRef,
                markersBuffer: this.markersBuffer,
                sceneRef : this.sceneRef,
                lastSelectedMarker: this.lastSelectedMarkerRef,
                annotation_metadata: annotations[i]
            })
            annotationMarker.addToWorld(true);
        }
    }


    initilaiseNavigations(navigations) {
        for (let i = 0; i < navigations.length; i++) {
            let navigationRing = new NavRing({
                currPreviewRingObj: this.previewRingRef,
                lastHoveredNavigationObj: this.lastHoveredRingRef,
                navigationRingsBuffer: this.navigationRingsBuffer,
                currModeRef: this.currModeRef,
                setCurrMode: this.setCurrMode,
                setCurrentImageIndex: this.setCurrentImageIndex,
                nav_point_idx: this.addNavSceneidx,
                sceneRef: this.sceneRef,
                navigation_metadata: navigations[i],
                isPreview: false
            })
            navigationRing.addToWorld()
        }

    }

    initaliseEventListener(){
        return new DesktopEventListeners({
            cameraRef: this.cameraRef,
            raycasterRef: this.raycasterRef,
            rendererRef: this.rendererRef,
            lastSelectedMarkerRef: this.lastSelectedMarkerRef,
            setCurrMode: this.setCurrMode,
            currModeRef: this.currModeRef,
            lastHoveredRingRef: this.lastHoveredRingRef,
            addNavSceneidx: this.addNavSceneidx,
            navigationRingsBuffer: this.navigationRingsBuffer,
            floorPlaneRef: this.floorPlaneRef,
            previewRingRef: this.previewRingRef,
            lon: this.lonRef,
            lat: this.latRef,
            onPointerDownMouseX: this.onPointerDownMouseX,
            onPointerDownMouseY: this.onPointerDownMouseY,
            onPointerDownLon: this.onPointerDownLon,
            onPointerDownLat: this.onPointerDownLat,
            setComment: this.setComment,
            markersBuffer: this.markersBuffer,
            sceneRef: this.sceneRef,
            setCurrentImageIndex: this.setCurrentImageIndex
        })
    }

}