import React, { useEffect, useState } from "react";
import "pannellum/build/pannellum.css";
import {
  MarkersPlugin,
  ReactPhotoSphereViewer,
  VirtualTourPlugin,
} from "react-photo-sphere-viewer";
import styled from "styled-components";
import { Add, AddCircleOutline, Check, Delete, LinkOutlined } from "@mui/icons-material";
import axios from "axios";

const Annotations = styled.div`
  width: 180px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
  padding: 0 10px;
  font-weight: 400;
  font-family: "Lexend", sans-serif;
  font-size: 15px;
  position: absolute;
  top: ${(props) => {
    return props.$top;
  }};
  left: ${(props) => {
    return props.$left;
  }};
  color: #ffffff;
  z-index: 99;
  border: 1px solid #acacac;
  border-radius: 0 10px 10px 10px;
`;

const Annotation = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 0;
  width: 150px;
  cursor: pointer;
`;

const Type = styled.div`
  background-color: ${(props) => props.$bg};
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 8px;
`;

const Text = styled.p`
  margin: 0;
`;

const DeleteIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const AnnotationsInfo = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
  padding: 0;
  font-weight: 400;
  font-family: "Lexend", sans-serif;
  font-size: 15px;
  position: absolute;
  top: ${(props) => {
    return props.$top;
  }};
  left: ${(props) => {
    return props.$left;
  }};
  color: #ffffff;
  z-index: 99;
  border-radius: 0 10px 10px 10px;
`;

const InfoTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px;
`;

const InfoType = styled.div`
  background-color: ${(props) => props.$bg};
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 2px;
`;

const InfoTitle = styled.div`
  padding: 5px;
  border: none;
  background-color: transparent;
  font-weight: 300;
  font-family: "Lexend", sans-serif;
  font-size: 14px;
  color: white;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #a3a3a3;
    font-weight: 300;
    font-family: "Lexend", sans-serif;
    font-size: 14px;
  }
`;

const InfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoBottom = styled.div`
  border-top: 1px solid #575757;
  padding: 5px;
`;

const InfoText = styled.textarea`
  width: 200px;
  background-color: transparent;
  border: none;
  color: white;
  font-weight: 300;
  font-family: "Lexend", sans-serif;
  font-size: 14px;
  resize: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #a3a3a3;
    font-weight: 300;
    font-family: "Lexend", sans-serif;
    font-size: 14px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
`;

const Input = styled.input``;

const NameInput = styled(Input)`
  width: 100%;
  font-size: 15px;
  padding: 10px 10px 5px 10px;
  border: none;
  border-bottom: 1px solid #0073bd;
  background-color: transparent;
  color: white;


  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 15px;
  }
`;

// const BottomContainer = styled.div`
//   width: 100%;
//   display: flex;
//   margin-top: 20px;
// `;

// const BottomText = styled.p`
//   flex: 1;
//   text-align: center;
//   color: ${(props) => props.$color};
//   margin: 0;
//   border-left: ${(props) => (props.$right ? "1px solid #a7a7a7" : "none")};
//   font-weight: 400;
// `;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.label`
  background-color: #1e73bd;
  color: white;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
`;

const DeleteFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectSceneOption = styled.div`
  display: flex;
  padding: 10px 0 10px 10px;
  align-items: center;
`

const SelectSceneIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
`

const SelectScene = styled.p`
  margin: 0;
`

const LinkingScene = styled.p`
  display: block;
  margin: 0;
  padding: 10px 5px 0px 5px;
`

const PannellumViewer = ({ img, hotspot, id, setHotspot, scenes }) => {
  // component parameters
  const [showAnnotations, setShowAnnotations] = useState(false);
  const [annotationsPosition, setAnnotationsPosition] = useState({
    x: 0,
    y: 0,
  });
  const [annotationValue, setAnnotationValue] = useState("");
  const [viewNonConformity, setViewNonConformity] = useState(false);
  const [viewComment, setViewComment] = useState(false);
  const [viewChangeOrder, setViewChangeOrder] = useState(false);
  const [stitch, setStitch] = useState(false);
  const [markerPlug, setMarkerPlug] = useState(null);
  const [pitch, setPitch] = useState(0);
  const [yaw, setYaw] = useState(0);
  const [displayOnRight, setDisplayOnRight] = useState(false);
  const [displayOnTop, setDisplayOnTop] = useState(false);
  const [file, setFile] = useState(null)
  const [virtualPlug, setVitualPlug] = useState(null);
  const [nodeId, setNodeId] = useState("")
  const [linkingSceneId, setLinkingSceneId] = useState("")
  const [linkingSceneName, setLinkingSceneName] = useState("")
  const [selectStitchOption, setSelectStitchOption] = useState(false)
  const [showLinkingSceneOptions, setShowLinkingSceneOptions] = useState(false)
  const [showLinkingScene, setShowLinkingScene] = useState(false)
  const [stitchSceneName, setStitchSceneName] = useState("")
  const [filteredArray, setFilteredArray] = useState([])
  const panoramaContainer = document.querySelector(`.panorama`);
  const nonConformityColor = "#fb1616";
  const commentColor = "#fbe30d";
  const changeOrderColor = "#fb9f16";
  let timeout;
  let isMouseHeld = false;
  let isDragging = false;
  let isTouchHeld = false;
  let isTouchMoving = false;

  // api endpoints
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrl2 = process.env.REACT_APP_BASE_URL2;
  const endPoint1 = "/reports/";
  const endPoint2 = "/add-hotspot";
  const endPoint3 = "/add-scene";

  // set filtered array
  useEffect(() => {
    const filtered = scenes.filter(scene => scene.scene_id !== `${nodeId}`)
    setFilteredArray(filtered)
  }, [nodeId, scenes])

  useEffect(() => {
    console.log(showAnnotations);
  }, [showAnnotations])

  // function to show annotations container
  const handleClickAndHold = (e) => {
    e.preventDefault()
    isMouseHeld = true;
    if (e && isMouseHeld) {
      timeout = setTimeout(() => {
        if (!isDragging) {
          const rect = panoramaContainer.getBoundingClientRect();
          const x = e.clientX - rect.left;
          const y = e.clientY - rect.top;
          const x1 = x - 180;
          const y1 = y - 122;
          if ((x / rect.width) * 100 > 65) {
            setDisplayOnRight(true);
            setAnnotationsPosition({ x: x1, y });
          } else if ((y / rect.height) * 100 > 70) {
            setDisplayOnTop(true);
            setAnnotationsPosition({ x, y: y1 });
          } else {
            setDisplayOnRight(false);
            setDisplayOnTop(false);
            setAnnotationsPosition({ x, y });
          }
          if ((x / rect.width) * 100 > 65 && (y / rect.height) * 100 > 70) {
            setDisplayOnRight(true);
            setDisplayOnTop(true);
            setAnnotationsPosition({ x: x1, y: y1 });
          }

          setShowAnnotations(true);
        }
      }, 1500);
    }
  };

  // function for mouse move
  const handleMouseMove = () => {
    if (isMouseHeld) {
      isDragging = true;
    }
    if (isTouchHeld) {
      isTouchMoving = true;
    }
  };

  // function on release
  const handleRelease = () => {
    clearTimeout(timeout);
    isMouseHeld = false;
    isDragging = false;
    isTouchHeld = false;
    isTouchMoving = false;
  };

  // function for touch hold
  const touchClickAndHold = (e) => {
    e.preventDefault();
    isTouchHeld = true;
    if (e && isTouchHeld) {
      timeout = setTimeout(() => {
        if (!isTouchMoving) {
          const rect = panoramaContainer.getBoundingClientRect();
          const x = e.touches[0].clientX - rect.left;
          const y = e.touches[0].clientY - rect.top;
          const x1 = x - 180;
          const y1 = y - 122;
          if ((x / rect.width) * 100 > 55) {
            setDisplayOnRight(true);
            setAnnotationsPosition({ x: x1, y });
          } else if ((y / rect.height) * 100 > 70) {
            setDisplayOnTop(true);
            setAnnotationsPosition({ x, y: y1 });
          } else {
            setDisplayOnRight(false);
            setDisplayOnTop(false);
            setAnnotationsPosition({ x, y });
          }
          if ((x / rect.width) * 100 > 55 && (y / rect.height) * 100 > 70) {
            setDisplayOnRight(true);
            setDisplayOnTop(true);
            setAnnotationsPosition({ x: x1, y: y1 });
          }

          setShowAnnotations(true);

          // console.log("touched");
        }
      }, 1500);
    }
  };

  // hide annotations container on screen click
  const hideAnnotations = () => {
    setShowAnnotations(false);
    setViewNonConformity(false);
    setViewComment(false);
    setViewChangeOrder(false);
    setSelectStitchOption(false);
    setShowLinkingSceneOptions(false);
    setShowLinkingScene(false);
    setStitch(false);
    setLinkingSceneId("");
    setLinkingSceneName("");
    setFile(null);
    // console.log("clicked");
  };

  // statement block to call annotations
  if (panoramaContainer) {
    // panoramaContainer.style.touchAction = "none"
    panoramaContainer.addEventListener("mousedown", handleClickAndHold);
    panoramaContainer.addEventListener("mousemove", handleMouseMove);
    panoramaContainer.addEventListener("mouseup", handleRelease);
    panoramaContainer.addEventListener("touchstart", touchClickAndHold);
    panoramaContainer.addEventListener("touchmove", handleMouseMove);
    panoramaContainer.addEventListener("touchend", handleRelease);
    panoramaContainer.addEventListener("click", hideAnnotations);
  }

  // function to show non conformity
  const showNonConformity = () => {
    setShowAnnotations(false);
    setViewNonConformity(true);
    // console.log("clicked");
  };

  // function to show Comment
  const showComment = () => {
    setShowAnnotations(false);
    setViewComment(true);
  };

  // function to show non conformity
  const showChangeOrder = () => {
    setShowAnnotations(false);
    setViewChangeOrder(true);
  };

  // show div to select stitch option
  const showStitchOption = () => {
    setShowAnnotations(false)
    setSelectStitchOption(true)
  }

  // show stitch
  const showStitch = () => {
    setSelectStitchOption(false);
    setStitch(true);
  }

  // show scenes that can be linked to
  const showLinkScenes = () => {
    setSelectStitchOption(false);
    setShowLinkingSceneOptions(true)
  }

  // confirm linking scene
  const confirmLinkScene = (sceneId, sceneName) => {
    setLinkingSceneId(sceneId)
    setLinkingSceneName(sceneName)
    setShowLinkingSceneOptions(false)
    setShowLinkingScene(true)
  }

  //set markers
  // const markers = hotspot.map((h, index) => ({
  //   id: "#" + index,
  //   position: { yaw: h.yaw, pitch: h.pitch },
  //   html: "<div></div>",
  //   style: {
  //     width: "15px",
  //     height: "15px",
  //     borderRadius: "50%",
  //     backgroundColor: h.type,
  //   },
  //   scale: {
  //     zoom: [0.75, 1],
  //   },
  //   tooltip: {
  //     content: h.text,
  //     position: "bottom center",
  //     trigger: "click",
  //   },
  // }));

  // console.log(markers);

  //set plugins
  const plugins = [
    [
      MarkersPlugin,
      // {
      //   markers: markers,
      // },
    ],
    [
      VirtualTourPlugin,
      {
        positionMode: "manual",
        renderMode: "3d",
      }
    ]
  ];

  // define scene/nodes
  const vtplg = scenes.map((scene, index) => {
    const markers = scene.hotspots.map((h, index) => ({
      id: `#${index}`,
      position: { yaw: h.yaw, pitch: h.pitch },
      html: "<div></div>",
      style: {
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        backgroundColor: h.type,
      },
      scale: {
        zoom: [0.75, 1],
      },
      tooltip: {
        content: h.text,
        position: "bottom center",
        trigger: "click",
      },
    }));
    return {
      id: scene.scene_id,
      name: scene.title,
      panorama: scene.panorama,
      markers: markers,
      links: scene.links.map(link => {
        return {
          nodeId: link.scene_id,
          position: {
            pitch: link.pitch,
            yaw: link.yaw
          }
        }
      }),
    }
  })

  // console.log(vtplg);

  // create an instance of the plugins
  const handleReady = (instance) => {
    // marker plugin intance
    const markersPluginInstance = instance.getPlugin(MarkersPlugin);
    if (markersPluginInstance) {
      setMarkerPlug(markersPluginInstance);
    }

    // virtualTour plugin instance
    const virtualTourPluginInstance = instance.getPlugin(VirtualTourPlugin);
    if (virtualTourPluginInstance) {
      setVitualPlug(virtualTourPluginInstance)
    }

    virtualTourPluginInstance.setNodes(vtplg)

    // virtualTourPluginInstance.addEventListener("node-changed", ({ node, data }) => {
    //   console.log("current node:", node.id);
    //   if (data.fromNode) {
    //     console.log("prev node:", data.fromNode.id)
    //     console.log(data);
    //   }
    // })

    if (markerPlug) {
      markerPlug.addEventListener("select-marker", () => {
        console.log("marker clicked");
      });
    }
  };

  // set nodes when scene changes
  virtualPlug?.addEventListener("node-changed", ({ node, data }) => {
    // console.log("current node:", node.id);
    setNodeId(node.id)
    // if (data.fromNode) {
    //   console.log("prev node:", data.fromNode.id)
    //   console.log(data);
    // }
  })

  // useEffect(() => {
  //   console.log(nodeId);
  // }, [nodeId])

  // function to handle photo sphere click
  const handleClick = (data) => {
    console.log(data.data);
    if (data.data) {
      const newPitch = data.data.pitch;
      const newYaw = data.data.yaw;
      setPitch(newPitch);
      setYaw(newYaw);
    }
    hideAnnotations();
  };

  // add annotation on screen and to db
  const addAnnotation = async (bgcolor) => {
    const newHotspot = {
      yaw: yaw,
      pitch: pitch,
      type: bgcolor,
      text: annotationValue,
      source_scene_id: nodeId,
      destination_scene_id: linkingSceneId,
    };

    // add marker to db
    try {
      const res = await axios.post(
        `${baseUrl2}${endPoint1}${id}${endPoint2}`,
        newHotspot
      );
      console.log(res.data);

      // add marker to screen
      if (markerPlug) {
        markerPlug.addMarker({
          id: "#" + Math.floor(Math.random() * 100),
          position: { yaw: yaw, pitch: pitch },
          html: "<div></div>",
          style: {
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: bgcolor,
          },
          scale: {
            zoom: [0.75, 1],
          },
          tooltip: {
            content: annotationValue,
            position: "bottom left",
            trigger: "click",
          },
        });
      }
      hideAnnotations()
    } catch (error) {
      console.log(error);
    }
  };

  // add new node/scene
  const addScene = async () => {
    const data = new FormData()
    data.append("pitch", pitch)
    data.append("yaw", yaw)
    data.append("source_scene_id", nodeId)
    data.append("destination_scene_id", linkingSceneId)
    data.append("type", "scene")
    data.append("name", stitchSceneName)
    data.append("picture", file)
    if (file) {
      try {
        const res = await axios.post(`${baseUrl2}${endPoint1}${id}${endPoint3}`, data)
        console.log(res.data);
        hideAnnotations();
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("attatch a file");
    }
  }

  // link to existing scene
  const linkScene = async () => {
    const data = {
      yaw: yaw,
      pitch: pitch,
      type: "scene",
      source_scene_id: nodeId,
      destination_scene_id: linkingSceneId,
    }
    try {
      const res = await axios.post(`${baseUrl2}${endPoint1}${id}${endPoint2}`, data)
      console.log(res.data);
      hideAnnotations();
    } catch (error) {
      console.log(error);
    }
  }

  //set Hotspots
  // useEffect(() => {
  //   const fetchHotspots = async () => {
  //     try {
  //       const res = await axios.get(`${baseUrl2}${endPoint1}${id}`, {
  //         headers: { "ngrok-skip-browser-warning": 1 },
  //       });
  //       console.log(res.data);
  //       setHotspot(res.data.hotspots_in_db);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchHotspots();

  //   const interval = setInterval(fetchHotspots, 20000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [baseUrl2, id, setHotspot]);

  return (
    <>
      <div
        className="panorama"
        style={{
          width: "100%",
          height: "100%",
          touchAction: "none",
          overflow: "hidden",
        }}
      >
        <ReactPhotoSphereViewer
          src={img}
          height={"100%"}
          width={"100%"}
          keyboard="false"
          plugins={plugins}
          onReady={handleReady}
          onClick={handleClick}
        ></ReactPhotoSphereViewer>
      </div>
      {/* dynamically displayed divs */}
      {showAnnotations && (
        <Annotations
          $top={`${annotationsPosition.y}px`}
          $left={`${annotationsPosition.x}px`}
        >
          <Annotation onClick={showNonConformity}>
            <Type $bg="#fb1616"></Type>
            <Text>Non-conformity</Text>
          </Annotation>
          <Annotation onClick={showComment}>
            <Type $bg="#fbe30d"></Type>
            <Text>Comment</Text>
          </Annotation>
          <Annotation onClick={showChangeOrder}>
            <Type $bg="#fb9f16"></Type>
            <Text>Change Order</Text>
          </Annotation>
          <Annotation onClick={showStitchOption}>
            <DeleteIcon>
              <Add />
            </DeleteIcon>
            <Text>Stitch</Text>
          </Annotation>
          {/* <Annotation>
            <DeleteIcon>
              <BackspaceOutlined />
            </DeleteIcon>
            <Text>Delete</Text>
          </Annotation> */}
        </Annotations>
      )}
      {viewNonConformity && (
        <AnnotationsInfo
          $top={`${annotationsPosition.y}px`}
          $left={`${annotationsPosition.x}px`}
        >
          <InfoTop>
            <InfoType $bg="#fb1616"></InfoType>
            <InfoTitle>Add Nonconformity</InfoTitle>
            <InfoIcon onClick={() => addAnnotation(nonConformityColor)}>
              <Check sx={{ color: "#0073bd" }} />
            </InfoIcon>
          </InfoTop>
          <InfoBottom>
            <InfoText
              placeholder="Non-conformity"
              rows={4}
              cols={100}
              onChange={(e) => setAnnotationValue(e.target.value)}
              value={annotationValue}
            ></InfoText>
          </InfoBottom>
        </AnnotationsInfo>
      )}
      {viewComment && (
        <AnnotationsInfo
          $top={`${annotationsPosition.y}px`}
          $left={`${annotationsPosition.x}px`}
        >
          <InfoTop>
            <InfoType $bg="#fbe30d"></InfoType>
            <InfoTitle>Add Comment</InfoTitle>
            <InfoIcon onClick={() => addAnnotation(commentColor)}>
              <Check sx={{ color: "#0073bd" }} />
            </InfoIcon>
          </InfoTop>
          <InfoBottom>
            <InfoText
              placeholder="Comment"
              rows={4}
              cols={100}
              onChange={(e) => setAnnotationValue(e.target.value)}
              value={annotationValue}
            ></InfoText>
          </InfoBottom>
        </AnnotationsInfo>
      )}
      {viewChangeOrder && (
        <AnnotationsInfo
          $top={`${annotationsPosition.y}px`}
          $left={`${annotationsPosition.x}px`}
        >
          <InfoTop>
            <InfoType $bg="#fb9f16"></InfoType>
            <InfoTitle>Change Order</InfoTitle>
            <InfoIcon onClick={() => addAnnotation(changeOrderColor)}>
              <Check sx={{ color: "#0073bd" }} />
            </InfoIcon>
          </InfoTop>
          <InfoBottom>
            <InfoText
              placeholder="Order"
              rows={4}
              cols={100}
              onChange={(e) => setAnnotationValue(e.target.value)}
              value={annotationValue}
            ></InfoText>
          </InfoBottom>
        </AnnotationsInfo>
      )}
      {selectStitchOption && (
        <AnnotationsInfo
          $top={`${annotationsPosition.y}px`}
          $left={`${annotationsPosition.x}px`}
        >
          <SelectSceneOption onClick={showStitch}>
            <SelectSceneIcon>
              <AddCircleOutline />
            </SelectSceneIcon>
            <SelectScene>Add a new scene</SelectScene>
          </SelectSceneOption>
          {filteredArray.length > 0 && <SelectSceneOption onClick={showLinkScenes}>
            <SelectSceneIcon>
              <LinkOutlined />
            </SelectSceneIcon>
            <SelectScene>Stitch to existing scene</SelectScene>
          </SelectSceneOption>}
        </AnnotationsInfo>
      )}
      {stitch && (
        <AnnotationsInfo
          $top={`${annotationsPosition.y}px`}
          $left={`${annotationsPosition.x}px`}
        >
          <InfoTop>
            <InfoTitle>Stitch panorama</InfoTitle>
            <InfoIcon onClick={addScene}>
              <Check sx={{ color: "#0073bd" }} />
            </InfoIcon>
          </InfoTop>
          <InfoBottom>
            <InputContainer>
              <NameInput
                type="text"
                placeholder="Scene Name"
                value={stitchSceneName}
                onChange={(e) => setStitchSceneName(e.target.value)}
              />
            </InputContainer>
            <InputContainer
              style={{
                margin: "10px 0",
                fontWeight: "300",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  marginRight: "20px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap"
                }}>
                {file ? file.name : "Report Picture"}
              </span>
              <LabelContainer>
                <Label htmlFor="file">
                  Upload
                  <Input
                    type="file"
                    id="file"
                    style={{ display: "none" }}
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                  />
                </Label>
                {file && (
                  <DeleteFile
                    onClick={() => {
                      setFile(null);
                    }}
                  >
                    <Delete sx={{ color: "red" }} />
                  </DeleteFile>
                )}
              </LabelContainer>
            </InputContainer>
            {/* <BottomContainer>
          <BottomText $color="red">
            <span style={{ cursor: "pointer" }}>
              Cancel
            </span>
          </BottomText>
          <BottomText $color="#1E73BD" $right>
            <span style={{ cursor: "pointer" }}>
              Confirm
            </span>
          </BottomText>
              </BottomContainer> */}
          </InfoBottom>
        </AnnotationsInfo>
      )}
      {showLinkingSceneOptions && (
        <AnnotationsInfo
          $top={`${annotationsPosition.y}px`}
          $left={`${annotationsPosition.x}px`}
        >
          {filteredArray.map(scene => (
            <LinkingScene key={scene.scene_id}
              onClick={() => { confirmLinkScene(scene.scene_id, scene.title) }}
            >
              {`Link to scene #${scene.scene_id}: ${scene.title}`}
            </LinkingScene>
          ))}
        </AnnotationsInfo>
      )}
      {showLinkingScene && (
        <AnnotationsInfo
          $top={`${annotationsPosition.y}px`}
          $left={`${annotationsPosition.x}px`}
        >
          <InfoTop>
            <InfoTitle>Stitch panorama</InfoTitle>
            <InfoIcon onClick={linkScene}>
              <Check sx={{ color: "#0073bd" }} />
            </InfoIcon>
          </InfoTop>
          <InfoBottom>
            <p style={{ padding: "10px 5px", margin: "0" }}>
              {`Stitch scene #${linkingSceneId}: ${linkingSceneName} to this scene?`}
            </p>
          </InfoBottom>
        </AnnotationsInfo>
      )}
    </>
  );
};

export default PannellumViewer;
