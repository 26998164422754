import { Cancel, KeyboardArrowUp } from "@mui/icons-material";
import PreviewSceneItem from "./previewSceneItem";
import React, { useState } from "react";
import { Modes } from "../utils";

const SceneList = ({
  scenes,
  selectedItem,
  setSelectedItem,
  addNavSceneidx,
  setCurrMode,
}) => {
  const [hideSceneBox, setHideSceneBox] = useState(false);

  // function for image click
  const handleImageClick = (idx) => {
    setSelectedItem(idx);
    setHideSceneBox(true);
    addNavSceneidx.current = idx;
  };

  // function to show chosen scenes
  const showScenes = () => {
    setHideSceneBox(false);
  };

  return (
    <>
      <div
        className={`absolue bottom-10 left-[50%] z-50 translate-x-[-50%] bg-black text-white rounded-full p-5 ${
          !hideSceneBox && `hidden`
        }`}
        onClick={showScenes}
      >
        <KeyboardArrowUp />
      </div>
      <div
        className={`absolute bottom-0 left-0 z-50 w-full p-5 bg-black rounded-t-lg ${
          hideSceneBox && "hidden"
        }`}
      >
        <div className="flex items-center justify-between my-5">
          <p className="text-base text-white">Select Scene to Add</p>
          <div
            className="flex items-center justify-center"
            onClick={() => setCurrMode(Modes.DEFAULT)}
          >
            <Cancel sx={{ color: "red" }} />
          </div>
        </div>
        <div className="flex items-center justify-start gap-5 overflow-x-scroll">
          {scenes.map(({ idx, imageUrl }, index) => (
            <PreviewSceneItem
              key={idx}
              src={imageUrl}
              onClick={() => handleImageClick(idx)}
              isSelected={idx === selectedItem}
              setHideSceneBox={setHideSceneBox}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SceneList;
