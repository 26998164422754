import { Add, Delete } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

// const Container = styled.div`
//   width: 140px;
//   height: 130px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: 1px dotted #adadad;
//   border-radius: 5px;
//   cursor: pointer;
//   position: relative;
//   margin-bottom: 10px;
// `;

// const TextContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-family: "Lexend", sans-serif;
//   font-weight: 300;
//   font-size: 15px;
//   color: #757575;
// `;

// const AddContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-right: 5px;
// `;

// const Text = styled.p`
//   margin: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const UploadContainer = styled.div`
  position: absolute;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const AddNewContainer = styled.div`
  width: 300px;
  padding: 10px 10px 10px 10px;
  background-color: white;
  border-radius: 20px;
  z-index: 9999;
  font-family: "Lexend", sans-serif;
  box-shadow: 12px 10px 32px 2px rgba(0, 0, 0, 0.7);
`;

const Title = styled.h3`
  text-align: center;
  font-weight: 500;
  color: #1e73bd;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const AddText = styled.div`
  padding: 10px;

  &:hover {
    color: #1e73bd;
  }
`;

const Hr = styled.hr`
  width: 100%;
`;

const InputContainer = styled.div`
  width: 100%;
`;
const Input = styled.input``;

const FolderInput = styled(Input)`
  width: 100%;
  font-size: 15px;
  padding: 10px 10px 3px 10px;
  border: none;
  border-bottom: 1px solid black;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 15px;
  }
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`;

const BottomText = styled.p`
  flex: 1;
  text-align: center;
  color: ${(props) => props.color};
  margin: 0;
  border-left: ${(props) => (props.$right ? "1px solid #a7a7a7" : "none")};
  font-weight: 400;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.label`
  background-color: #1e73bd;
  color: white;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
`;

const DeleteFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadItem = ({ addNewItem, setAddNewItem, setData, id }) => {
  // component states
  const [file, setFile] = useState(null);
  const [folderName, setFolderName] = useState("");
  // const [folderId, setFolderId] = useState(null);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showFolderUpload, setShowFolderUpload] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [showChooseOption, setShowChooseOption] = useState(true);
  const location = useLocation();
  const [endPoint, setEndPoint] = useState("");

  // api endpoints
  const baseUrl = process.env.REACT_APP_BASE_URL2;

  // check for location
  useEffect(() => {
    if (location.pathname.includes("/drawings/")) {
      setEndPoint("/drawings");
    } else if (location.pathname.includes("/ncr/")) {
      setEndPoint("/ncr");
    } else if (location.pathname.includes("/qa_qc/")) {
      setEndPoint("/qa_qc");
    }
  }, [location.pathname]);

  // hide new ncrs container
  const hideAddNewItem = () => {
    setAddNewItem(false);
  };

  // choose folder upload
  const selectFolder = () => {
    setShowChooseOption(false);
    setShowFolderUpload(true);
    setShowConfirmButton(true);
  };

  // choose file upload
  const selectFile = () => {
    setShowChooseOption(false);
    setShowFileUpload(true);
    setShowConfirmButton(true);
  };

  // update data in folder
  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}${endPoint}/${id}`);
      setData(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // create new file or folder
  const createNewItem = async () => {
    const data = new FormData();
    if (showFileUpload) {
      data.append("document", file);
      data.append("folderId", id);
      try {
        const res = await axios.post(`${baseUrl}${endPoint}`, data, {
          headers: {
            "ngrok-skip-browser-warning": 1,
          },
        });
        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    }

    if (showFolderUpload) {
      const folder = {
        name: folderName,
        folderId: id,
      };
      try {
        const res = await axios.post(
          `${baseUrl}${endPoint}/create-folder`,
          folder
        );
        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    }

    getData();
    setAddNewItem(false);
  };

  return (
    <UploadContainer>
      <AddNewContainer>
        <Title>Add New Item</Title>
        {showChooseOption && (
          <SelectContainer>
            <AddText onClick={selectFile}>Add New File</AddText>
            <Hr />
            <AddText onClick={selectFolder}>Add New Folder</AddText>
          </SelectContainer>
        )}
        {showFolderUpload && (
          <InputContainer>
            <FolderInput
              type="text"
              placeholder="Folder Name"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
            />
          </InputContainer>
        )}
        {showFileUpload && (
          <InputContainer
            style={{
              marginTop: "20px",
              fontWeight: "300",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: "20px" }}>
              {file ? file.name : "Select File"}
            </span>
            <LabelContainer>
              <Label htmlFor="file">
                Upload
                <Input
                  type="file"
                  id="file"
                  style={{ display: "none" }}
                  accept="application/pdf"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />
              </Label>
              {file && (
                <DeleteFile
                  onClick={() => {
                    setFile(null);
                  }}
                >
                  <Delete sx={{ color: "red" }} />
                </DeleteFile>
              )}
            </LabelContainer>
          </InputContainer>
        )}
        <BottomContainer>
          <BottomText color="red">
            <span style={{ cursor: "pointer" }} onClick={hideAddNewItem}>
              Cancel
            </span>
          </BottomText>
          {showConfirmButton && (
            <BottomText color="#1E73BD" $right>
              <span style={{ cursor: "pointer" }} onClick={createNewItem}>
                Confirm
              </span>
            </BottomText>
          )}
        </BottomContainer>
      </AddNewContainer>
    </UploadContainer>
  );
};

const AddNew = ({ addNewItem, setAddNewItem, setData, id }) => {
  const showAddNew = () => {
    setAddNewItem(true);
  };

  return (
    <>
      {addNewItem && (
        <UploadItem
          addNewItem={addNewItem}
          setAddNewItem={setAddNewItem}
          setData={setData}
          id={id}
        />
      )}
      <div style={{ flex: "1", maxWidth: "100%" }}>
        <div
          className="w-full p-5 flex items-center border-dashed border-[0.5px] border-[#9c9c9c] relative"
          onClick={showAddNew}
        >
          <div className="flex items-center font-light text-base text-[#8b8b8b]" style={{ fontFamily: "Lexend, sans-serif" }}>
            <div className="h-8 w-8 border-dashed border-[0.5px] border-[#9c9c9c] flex items-center justify-center mr-5">
              <Add />
            </div>
            <p className="m-0 text-[15px]">
              <span>Add new folder</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNew;
