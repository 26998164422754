import { useContext } from "react";
import Homepage from "./pages/Homepage";
import Panorama from "./pages/Panorama";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AuthContext } from "./context/authContext";
import GoogleAuth from "./components/GoogleAuth";
import AutoDeskAuth from "./components/AutoDeskAuth";
import PDFViewer from "./pages/PdfViewer";
import FolderViewer from "./pages/FolderViewer";
import Comments from "./pages/Comments";
import Spaces from "./pages/Spaces";
import Program from "./pages/Program";
import Plans from "./pages/Plans";

function App() {
  const { user } = useContext(AuthContext);
  return (
    <div>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={user ? <Homepage /> : <Navigate to="/signin" />}
          />
          <Route
            path="/signin"
            element={user ? <Navigate to="/" /> : <SignUp />}
          />
          <Route
            path="/signup"
            element={user ? <Navigate to="/" /> : <Profile />}
          />
          <Route
            path="/reports"
            element={user ? <Spaces /> : <Navigate to="/signin" />}
          />
          <Route
            path="/comments"
            element={user ? <Comments /> : <Navigate to="/signin" />}
          />
          <Route
            path="/pow"
            element={user ? <Program /> : <Navigate to="/signin" />}
          />
          <Route
            path="/plans"
            element={user ? <Plans /> : <Navigate to="/signin" />}
          />
          <Route
            path="/reports/:id"
            element={user ? <Panorama /> : <Navigate to="/signin" />}
          />
          <Route path="/drawings/pdf/:id" element={<PDFViewer />} />
          <Route path="/drawings/folder/:id" element={<FolderViewer />} />
          <Route path="/ncr/pdf/:id" element={<PDFViewer />} />
          <Route path="/ncr/folder/:id" element={<FolderViewer />} />
          <Route path="/qa_qc/pdf/:id" element={<PDFViewer />} />
          <Route path="/qa_qc/folder/:id" element={<FolderViewer />} />
          <Route path="/google/callback" element={<GoogleAuth />} />
          <Route path="/autodesk/callback" element={<AutoDeskAuth />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
