import dummy_data from '../PanaromaUtils/mock_data/annotation_data';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import * as THREE from 'three';
import config from '../PanaromaUtils/config'
import FloorPlane from '../PanaromaUtils/customSceneObjects/floorPlane'
import SphericalWorld from '../PanaromaUtils/customSceneObjects/SphericalWorld';
import SceneList from "../PanaromaUtils/SceneCreationProps/SceneList"
import { Add, NavigateBefore, NavigateNext, Remove, Cancel } from '@mui/icons-material';
import {isMobileAndTablet, Modes } from "../PanaromaUtils/utils";
import { ParanomaInit } from '../PanaromaUtils/paranomaInit';

const PanoramaViewer = () => {

    const world_radius = config.world_radius;
    const window_width_scale = config.window_width_scale
   
    const [currMode, setCurrMode] = useState(Modes.DEFAULT)
    const [selectedNavPreviewItem, setSelectedNavPreviewItem] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [comment, setComment] = useState('');

    // let camera, renderer, raycaster;

    
    const lonRef = useRef(200);
    const latRef = useRef(0);

    let onPointerDownMouseX = 0, onPointerDownMouseY = 0;
    let onPointerDownLon = 0, onPointerDownLat = 0;

    const sceneRef = useRef();
    const rendererRef = useRef();
    const cameraRef = useRef();
    const raycasterRef = useRef()
    const containerRef = useRef();
    const floorPlaneRef = useRef(null);
    const previewRingRef = useRef(null);
    const addNavSceneidx = useRef(null);
    const currModeRef = useRef(Modes.DEFAULT)
    const lastHoveredRingRef = useRef(null);
    const lastSelectedMarkerRef = useRef(null); 

    const markersBuffer = useRef([])
    const navigationRingsBuffer = useRef([])

    const initParanorma  = InitParanorma()
    const desktopEventListenersRef = useRef(initParanorma.initaliseEventListener());



    useEffect(() => {
        const container = containerRef.current;
        cameraRef.current = new THREE.PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            1,
            1100);
        sceneRef.current = new THREE.Scene();
        if (!isInitialRender) {
            initParanorma.initialiseAnnotations(scenes[currentImageIndex].annotations);
            initParanorma.initilaiseNavigations(scenes[currentImageIndex].navigation)
        }
        const sphericalWorld = new SphericalWorld(scenes, currentImageIndex)
        sceneRef.current.add(sphericalWorld)

        raycasterRef.current = new THREE.Raycaster()
        // renderer = new THREE.WebGLRenderer();
        rendererRef.current = new THREE.WebGLRenderer({ antialias: true });
        rendererRef.current.setPixelRatio(window.devicePixelRatio);
        rendererRef.current.setSize(window.innerWidth * window_width_scale, window.innerHeight);
        container.appendChild(rendererRef.current.domElement)


        container.style.touchAction = 'none';

        container.addEventListener('pointerdown', onPointerDown);
        container.addEventListener('dblclick', onDoubleClick);
        window.addEventListener('resize', onWindowResize);

        if (!isMobileAndTablet()) {
            document.addEventListener('wheel', onDocumentMouseWheel);
            container.addEventListener("pointermove", onPointerMove);
        }
        // Update isInitialRender after the initial render
        if (isInitialRender) {
            setIsInitialRender(false);
        }
        animate();

        return () => {

            container.removeEventListener('pointerdown', onPointerDown);
            container.removeEventListener('dblclick', onDoubleClick);

            window.removeEventListener('resize', onWindowResize);
            container.removeChild(rendererRef.current.domElement);
            
            if (!isMobileAndTablet()) {
                document.removeEventListener('wheel', onDocumentMouseWheel);
                container.removeEventListener("pointermove", onPointerMove);
            }
        };
    }, [currentImageIndex, isInitialRender]);

    function onDocumentMouseWheel(event) {
        desktopEventListenersRef.current.onDocumentMouseWheel(event)
    }

    function onWindowResize(){
        desktopEventListenersRef.current.onWindowResize()
    }

    function onDoubleClick(event){
        desktopEventListenersRef.current.onDoubleClick(event)
    }

    function onPointerMove(event){
        desktopEventListenersRef.current.onPointerMove(event)
    }

    function onPointerDown(event){
        desktopEventListenersRef.current.onPointerDown(event)
        if (event.isPrimary === false) return;
        updateLastOnPointerVariables(event)
        document.addEventListener('pointermove', onPointerMovePan);
        document.addEventListener('pointerup',  onPointerUp);
    }

    function onPointerMovePan(event) { // on pointermove function called after clicking to allow panning
        if (event.isPrimary === false) return;
       lonRef.current = (onPointerDownMouseX - event.clientX) * config.sensitivity + onPointerDownLon;
       latRef.current = (event.clientY -  onPointerDownMouseY) * config.sensitivity + onPointerDownLat;
    }

    function onPointerUp(event) {
        if (event.isPrimary === false) return;
        document.removeEventListener('pointermove', onPointerMovePan);
        document.removeEventListener('pointerup',  onPointerUp);
    }

    function  updateLastOnPointerVariables( event ) {
        onPointerDownMouseX = event.clientX;
        onPointerDownMouseY = event.clientY;
        onPointerDownLon = lonRef.current;
        onPointerDownLat = latRef.current;
    }

    function animate() {
        requestAnimationFrame(animate);
        update();
    }

    function update() {
        latRef.current = Math.max(-85, Math.min(85, latRef.current));
        const phi = THREE.MathUtils.degToRad(90 - latRef.current);
        const theta = THREE.MathUtils.degToRad(lonRef.current);
        const target = new THREE.Vector3(
            world_radius * Math.sin(phi) * Math.cos(theta),  //x
            world_radius * Math.cos(phi),                    //y 
            world_radius * Math.sin(phi) * Math.sin(theta)); //z
        cameraRef.current.lookAt(target);
        rendererRef.current.render(sceneRef.current, cameraRef.current);
    }   


    function _setCurrMode(mode){
        // we often need to do  both together
        currModeRef.current = mode
        setCurrMode(mode)
    }

    // data functions
    const scenes = useMemo(() => loadData(), [])

    function loadData() { // async code that calls the api
        return Object.keys(dummy_data.scenes).map(key => ({
            imageUrl: dummy_data.scenes[key].image_url,
            annotations: dummy_data.scenes[key].annotations,
            navigation: dummy_data.scenes[key].navigation
        }));
    }

    const filteredImagesWithIndices = useMemo(() => {
        return scenes
            .map((scene, idx) => ({ idx, imageUrl: scene.imageUrl }))
            .filter(({ idx }) => idx !== currentImageIndex);
    }, [scenes, currentImageIndex]);



    // button call back functions
    const togglePlacingMode = () => {

        switch(currModeRef.current){
            
            case  Modes.DEFAULT:
                _setCurrMode(Modes.CreateNav)
                floorPlaneRef.current = new FloorPlane(sceneRef)
                floorPlaneRef.current.addToScene()
                break

            case Modes.CreateNav:
                sceneRef.current.remove(floorPlaneRef.current)
                addNavSceneidx.current = null
                setSelectedNavPreviewItem(null)
                if (previewRingRef.current) {
                    sceneRef.current.remove(previewRingRef.current)
                    previewRingRef.current = null;
                }
                _setCurrMode(Modes.DEFAULT)
                break
            default:
                break
        }

    };

    const toggleDeleteMode = () => {

        switch(currModeRef.current){
            case  Modes.DEFAULT:
                _setCurrMode(Modes.DeleteNav)
                break

            case Modes.CreateNav:
                break

            case Modes.DeleteNav:
                _setCurrMode(Modes.DEFAULT)
                break

            default:
                break
        }
    }


    function InitParanorma(){
        return new  ParanomaInit({
            setComment : setComment,
            setCurrMode : setCurrMode,
            setCurrentImageIndex : setCurrentImageIndex,
           
            markersBuffer : markersBuffer,
            navigationRingsBuffer : navigationRingsBuffer ,
            
            lonRef: lonRef,
            latRef: latRef,
            sceneRef : sceneRef,
            cameraRef: cameraRef,
            raycasterRef: raycasterRef,
            rendererRef: rendererRef,
            currModeRef : currModeRef,
            floorPlaneRef: floorPlaneRef,
            previewRingRef : previewRingRef,
            lastHoveredRingRef : lastHoveredRingRef,
            lastSelectedMarkerRef : lastSelectedMarkerRef,
    
            addNavSceneidx : addNavSceneidx,
            onPointerDownMouseX: onPointerDownMouseX,
            onPointerDownMouseY: onPointerDownMouseY,
            onPointerDownLon: onPointerDownLon,
            onPointerDownLat: onPointerDownLat,
        })
    }




    function
        handleNextButtonClick() {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sceneRef.current.length);
    };

    function
        handlePrevButtonClick() {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + sceneRef.current.length) % sceneRef.current.length);
    };

    const submitComment = () => {
        if (lastSelectedMarkerRef !== null) {
            lastSelectedMarkerRef.current.description = comment
            _setCurrMode(Modes.DEFAULT)
            lastSelectedMarkerRef.current = null;
        }
    };

    // on text and value changes UI
    const handleTextareaChange = (event) => {
        setComment(event.target.value); // Update the comment state with the new textarea value
    };

    // cancel comment
    const cancelComment = () => {
        _setCurrMode(Modes.DEFAULT)
    }



    return (
        <div className='relative overflow-hidden'>
            <div className='w-screen h-screen overflow-hidden touch-none'>
                <div ref={containerRef}></div>
            </div>
            <div className='absolute bottom-0 w-full'> {/* Adjust padding and margin as needed */}
                <div className='relative flex items-center justify-center gap-10'>
                    <div className='absolute z-20 flex items-center justify-between gap-5 mb-12 bottom-10'>
                        <button
                            className='z-30 w-10 h-10 overflow-hidden text-white bg-black rounded-full flexCenter'
                            onClick={handlePrevButtonClick}
                        >
                            <NavigateBefore />
                        </button>
                        {config.admin && (
                            <button
                                className='z-30 w-10 h-10 overflow-hidden text-white bg-black rounded-full flexCenter'
                                onClick={togglePlacingMode}
                            >
                                {currMode === Modes.CreateNav ? <Cancel /> : <Add />}
                            </button>

                            
                        )}
                        {config.admin && (
                            <button
                                className='z-30 w-10 h-10 overflow-hidden text-white bg-black rounded-full flexCenter'
                                onClick={toggleDeleteMode}
                            >
                                {currMode !== Modes.DeleteNav ? <Remove /> : <Cancel />}
                            </button>
                        )}
                        <button
                            className='z-30 w-10 h-10 overflow-hidden text-white bg-black rounded-full flexCenter'
                            onClick={handleNextButtonClick}
                        >
                            <NavigateNext />
                        </button>
                    </div>
                    {currMode === Modes.EditingAnnotation && (
                        <div className='absolute bottom-0 left-0 z-40 w-full p-5 bg-white rounded-t-lg'>
                            <div className='text-sm font-semibold text-black'>New Comment</div>
                            <textarea
                                rows="5"
                                cols="70"
                                placeholder="Add Comment..."
                                className='my-5 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm text-[15px] placeholder:text-[#bababa] border p-2 border-[#BABABA] rounded-[7px]'
                                value={comment}
                                onChange={handleTextareaChange}
                            />
                            <div className="flex justify-between gap-5 mb-2">
                                <p className="cursor-pointer bg-[#D9D9D9] text-center text-[#DB0707] w-3/6 rounded-[7px] px-4 py-2" onClick={cancelComment}>Cancel</p>
                                <p className="cursor-pointer bg-[#333333] text-center w-3/6 rounded-[7px] text-white px-4 py-2 " onClick={submitComment}>Confirm</p>
                            </div>
                        </div>
                    )}
                    {config.admin && currMode === Modes.CreateNav && (
                        <SceneList
                            scenes={filteredImagesWithIndices}
                            selectedItem={selectedNavPreviewItem}
                            setSelectedItem={setSelectedNavPreviewItem}
                            addNavSceneidx={addNavSceneidx}
                            setCurrMode={setCurrMode}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PanoramaViewer;