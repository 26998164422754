import { Article, ChatBubble, FolderOff, HomeRounded, Logout, MenuSharp, Search, SpaceDashboardOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { VscMenu } from "react-icons/vsc";
import { motion } from "framer-motion";
// import styled from "styled-components";
import logo from "../media/Buildr LOGO.png";
import profilePicture from "../media/profile photo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Logo } from "../media/svgs/logo";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";


const TopBar = ({ home }) => {

  // home page topbar
  const HomeTopbar = () => {
    return (
      <div className="sticky top-0 left-0 z-20">
        <div className="flex flex-col items-center justify-end p-5 bg-white min-h-32 ">
          <div className="flex flex-col items-center justify-center">
            {/* <img src={LogoImg} alt="" /> */}
            {Logo}
            <p className="text-[15px] jeju text-[#686767] text-center">Your site, Anytime, Anywhere.</p>
          </div>
        </div>
        <div className="flex items-center justify-center w-full h-20 bg-[#f0f0f0] py-8 px-[5%]">
          <div className="flex items-center justify-between w-full h-full ">
            <div className="flex items-center bg-white p-1.5 max-w-[310px] w-full rounded-[7px] h-8 mr-2 border border-[#d2d2d2]">
              <div className="flex items-center justify-center">
                <Search sx={{ color: "#d2d2d2" }} fontSize="small" />
              </div>
              <input
                className="placeholder:text-[17px] placeholder:text-[#9E9E9E] font-normal w-full stPro h-8 text-black bg-transparent border-none focus:outline-none"
                type="text"
                placeholder="Search"
              />
            </div>
            <div className="flex items-center justify-center">
              <img className="object-cover w-8 h-8 rounded-full" src={profilePicture} alt="" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  // other pages topbar
  const OtherTopbar = () => {
    const [isMenuActive, setIsMenuActive] = useState(false);
    const navigate = useNavigate();
    const [active, setActive] = useState("");
    const location = useLocation()
    const { logout } = useContext(AuthContext)

    // useEffect(() => {
    //   console.log(active);
    // }, [active])

    // toggle menu click
    const toggleIsMenuActive = () => {
      setIsMenuActive(prev => !prev);
    }

    const handleLogout = () => {
      logout()
    }

    // check state location and set active menu item
    useEffect(() => {
      switch (true) {
        case location.pathname.includes("reports"):
          setActive("Spaces")
          break;
        case location.pathname.includes("pow"):
          setActive("POW")
          break;
        case location.pathname.includes("comments"):
          setActive("Comments")
          break;
        case location.pathname.includes("plans"):
          setActive("Plans")
          break;
        default:
          setActive("Home")
      }
    }, [location.pathname])


    return (
      <div className="relative w-full">
        <div className="sticky top-0 left-0 z-20">
          <div className="flex flex-col items-center justify-end p-5 bg-white min-h-32 ">
            <div className="flex flex-col items-center justify-center">
              {/* <img src={LogoImg} alt="" /> */}
              {Logo}
              <p className="text-[15px] jeju text-[#686767] text-center">Your site, Anytime, Anywhere.</p>
            </div>
          </div>
          <div className="flex items-center px-[5%] min-h-[46px] justify-between w-full border-y-[0.5px] border-border bg-white">
            <div
              className="flex items-center justify-center"
              onClick={toggleIsMenuActive}
            >
              <VscMenu fontSize="35px" />
            </div>
            <div className="font-sans text-[17px] font-light text-black">
              {active}
            </div>
            <div className="flex items-center justify-center w-12 h-12">
              <img className="object-cover w-8 h-8 rounded-full" src={profilePicture} alt="" />
            </div>
          </div>
          <div className="flex items-center justify-center bg-[#f0f0f0] py-5">
            <div className="flex items-center justify-center">
              <div className="flex items-center bg-white px-2.5 w-full min-w-[310px] h-8 mr-2 rounded-[7px] border border-[#d2d2d2]">
                <div className="flex items-center justify-center">
                  <Search sx={{ color: "#BDBDBD" }} fontSize="small" />
                </div>
                <input
                  className="text-sm font-normal w-full h-8 text-black bg-transparent sfPro placeholder:text-[17px] placeholder:text-[#9E9E9E] border-none focus:outline-none"
                  type="text"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
        {isMenuActive && <div className="fixed top-0 left-0 z-30 h-screen w-screen bg-[#00000048]" onClick={toggleIsMenuActive} />}
        <motion.div
          className={`w-9/12 h-screen z-40 bg-[#1A1D22] fixed top-0 left-0 text-white flex flex-col justify-center`}
          initial={{ x: "-100%" }}
          animate={{ x: isMenuActive ? "0" : "-100%" }}
          transition={{ duration: isMenuActive ? 0.1 : 0.2 }}
        >
          <div className="w-full pr-[2px]">
            <div className="flex pt-5 px-8 pb-2.5 items-center justify-between">
              <span className="text-lg font-medium" style={{ fontFamily: "Lexend, sans-serif" }}>The Apartment</span>
              <HomeRounded fontSize="large" />
            </div>
            <div className="flex flex-col gap-[2px]">
              <div
                className={`px-5 py-3 h-[50px] ${active === "POW" ? "text-black" : "text-white"} ${active === "POW" ? "bg-primary" : "bg-[#313234]"} flex items-center`}
                style={{ fontFamily: "Lexend, sans-serif" }}
                onClick={() => navigate("/pow")}
              >
                <div className="flex items-center justify-center w-8 h-8 mr-2.5"><SpaceDashboardOutlined sx={{ fontSize: "28px" }} /></div>
                <div className="text-base font-light">Program of work</div>
              </div>
              <div
                className={`px-5 py-3 h-[50px] ${active === "Spaces" ? "text-black" : "text-white"} ${active === "Spaces" ? "bg-primary" : "bg-[#313234]"} flex items-center`}
                style={{ fontFamily: "Lexend, sans-serif" }}
                onClick={() => navigate("/reports")}
              >
                <div className="flex items-center justify-center w-8 h-8 mr-2.5"><FolderOff sx={{ fontSize: "28px" }} /></div>
                <div className="text-base font-light">Spaces</div>
              </div>
              <div
                className={`px-5 py-3 h-[50px] ${active === "Plans" ? "text-black" : "text-white"} ${active === "Plans" ? "bg-primary" : "bg-[#313234]"} flex items-center`}
                style={{ fontFamily: "Lexend, sans-serif" }}
                onClick={() => navigate("/plans")}
              >
                <div className="flex items-center justify-center w-8 h-8 mr-2.5"><Article sx={{ fontSize: "28px" }} /></div>
                <div className="text-base font-light">Plans</div>
              </div>
              <div
                className={`px-5 py-3 h-[50px] ${active === "Comments" ? "text-black" : "text-white"} ${active === "Comments" ? "bg-primary" : "bg-[#313234]"} flex items-center`}
                style={{ fontFamily: "Lexend, sans-serif" }}
                onClick={() => navigate("/comments")}
              >
                <div className="flex items-center justify-center w-8 h-8 mr-2.5"><ChatBubble sx={{ fontSize: "28px" }} /></div>
                <div className="text-base font-light">Comments</div>
              </div>
              <div
                className={`px-5 py-3 h-[50px] ${active === "Comments" ? "text-black" : "text-white"} ${active === "Comments" ? "bg-primary" : "bg-[#313234]"} flex items-center`}
                style={{ fontFamily: "Lexend, sans-serif" }}
                onClick={handleLogout}
              >
                <div className="flex items-center justify-center w-8 h-8 mr-2.5"><Logout sx={{ fontSize: "28px", transform: "scaleX(-1)" }} /></div>
                <div className="text-base font-light">Logout</div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    )
  }


  return (
    <>
      {home ? <HomeTopbar /> : <OtherTopbar />}
    </>
  );
};

export default TopBar;
